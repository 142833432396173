import React from "react";
import { connect, dispatch } from "react-redux";
import { initEvents } from "../../data-store/actions/events-actions";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Home1 from "./Home.1";
import BluetoothManager from "../../services/BluetoothManager";
import CustomQR from "../../services/CustomQR";

const HomeView = ({ events, onTodoClick, eventos, context }) => {
  return (
    <div>
      <h5 className="center" id="Pagina"></h5>
      <div className="container-home">
        <div className="header center grey-text text-darken-3">
          <div>
            <h1>Panel para usuarios autorizados Dashport</h1>
            <h4>
              Si eres organizador de eventos y no tienes una cuenta de usuarios
              autorizados ponte en contacto con info@dashport.run
            </h4>
          </div>
        </div>
        <div className="row center">
          <Button
            variant="contained"
            component={Link}
            to={"/eventos-organizados/"}
            color="primary"
            size="large"
          >
            Mis eventos organizados
          </Button>
          {/* <Home1 /> */}
          {/* <BluetoothManager /> */}
          {/* <CustomQR /> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTodoClick: () => {
      dispatch(initEvents());
    },
  };
};

const Home = connect(mapStateToProps, mapDispatchToProps)(HomeView);

export default Home;
