import React from "react";
import { connect } from "react-redux";
import {
  detalleEvento,
  resultadosEventoNew,
  resultReportType,
  getPuntosControl,
  configuracionEvento,
  eventSubEvents,
  juecesEvento,
} from "../../../../data-store/actions/events-actions";
import {
  eventParticipants,
  registryConfiguration,
  registryAutorizedUsers,
} from "../../../../data-store/actions/registry-actions";

import ControlPointRegistry from "./ControlPoints/ControlPointRegistry";
import ControlPointPhotoRegistry from "./ControlPointPhotoRegistry";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import DistancesAndCategories from "./DistancesCategories/DistancesAndCategories";
import ConvertRegistryDataToTimingContainer from "./ConvertRegistryDataToTimingData/ConvertRegistryDataToTimingContainer";
import ReaderConfig from "./ReaderConfig/ReaderConfig";
import AuthUsersTimingContainer from "./AuthUsersTiming/AuthUsersTimingContainer";
import SpecialReports from "./SpecialReports/SpecialReports";
import TimingConfig from "./TimingConfig/TimingConfig";
import StartsList from "./TimingRegistry/StartsList";
import CheckChip from "./CheckChip/CheckChip";
import CheckChipContainer from "./CheckChip/CheckChipContainer";
import ReadsContainer from "./Reads/ReadsContainer";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class AuthorizedUsersTimingContainerView extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  validateProps = () => {
    // para validar que todos los datos requeridos ya se descargaron para continuar
    if (
      this.props.eventResults != null &&
      this.props.eventResults != undefined &&
      this.props.eventSubEvents != null &&
      this.props.eventSubEvents != undefined &&
      this.props.eventConfiguration != null &&
      this.props.eventConfiguration != undefined &&
      this.props.eventUsers != null &&
      this.props.eventUsers != undefined &&
      this.props.puntosControl != null &&
      this.props.puntosControl != undefined
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { value } = this.state;
    let arrayTabs = [];
    let contentTabs = [];

    let userProfile = null;
    var convocatoriaShow;
    var tabConfig;

    arrayTabs.push(<Tab key="tab-Distances" label="Distancias y Categorías" />);
    contentTabs.push(
      <TabContainer key="contTab-Distances">
        <DistancesAndCategories />
      </TabContainer>
    );

    arrayTabs.push(<Tab key="tab-ConvertData" label="Convertir Data" />);
    contentTabs.push(
      <TabContainer key="contTab-ConvertData">
        <ConvertRegistryDataToTimingContainer />
      </TabContainer>
    );
    arrayTabs.push(<Tab key="tab-PuntosControl" label="PuntosControl" />);
    contentTabs.push(
      <TabContainer key="contTab-PuntosControl">
        <ControlPointRegistry />
      </TabContainer>
    );
    // arrayTabs.push(<Tab key="tab-Starts" label="Salidas" />);
    // contentTabs.push(
    //   <TabContainer key="contTab-Starts">
    //     <StartsList />
    //   </TabContainer>
    // );

    // arrayTabs.push(<Tab key="tab-Fotos" label="Fotos" />);
    // contentTabs.push(
    //   <TabContainer key="contTab-Fotos">
    //     <ControlPointPhotoRegistry eventId={this.props.eventDetail.iDEvento} />
    //   </TabContainer>
    // );
    arrayTabs.push(<Tab key="tab-Auth" label="Usuarios" />);
    contentTabs.push(
      <TabContainer key="contTab-Auth">
        <AuthUsersTimingContainer />
      </TabContainer>
    );
    arrayTabs.push(<Tab key="tab-CheckChip" label="Chip check" />);
    contentTabs.push(
      <TabContainer key="contTab-CheckChip">
        <CheckChipContainer />
      </TabContainer>
    );
    arrayTabs.push(<Tab key="tab-Reads" label="Leturas y Registros" />);
    contentTabs.push(
      <TabContainer key="contTab-Reads">
        <ReadsContainer />
      </TabContainer>
    );
    arrayTabs.push(<Tab key="tab-Reader" label="Dashport Reader" />);
    contentTabs.push(
      <TabContainer key="contTab-Reader">
        <ReaderConfig />
      </TabContainer>
    );
    arrayTabs.push(<Tab key="tab-Config" label="Timing Config" />);
    contentTabs.push(
      <TabContainer key="contTab-Config">
        <TimingConfig />
      </TabContainer>
    );
    // arrayTabs.push(<Tab key="tab-Reader" label="Reportes" />);
    // contentTabs.push(
    //   <TabContainer key="contTab-Reader">
    //     <SpecialReports />
    //   </TabContainer>
    // );

    let element;
    if (!this.validateProps()) {
      element = (
        <div className="row">
          <div className="col s12 m12 l12">
            <h3>Cargando resultados...</h3>
          </div>
        </div>
      );
    } else {
      userProfile = this.props.eventUsers[this.props.userID].juezTipo;

      element = (
        <Container maxWidth="lg">
          <Tabs
            className="z-depth-1 red-text"
            value={value}
            onChange={this.handleChange}
            scrollButtons="auto"
            variant="scrollable"
          >
            {arrayTabs}
          </Tabs>

          {contentTabs[value]}
        </Container>
      );
    }

    return element;
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  console.log(match.params.EventoIndex);
  return {
    eventSubEventsSend: dispatch(eventSubEvents(match.params.EventoIndex)),
    eventConfigurationSend: dispatch(
      configuracionEvento(match.params.EventoIndex)
    ),
    puntosControlSend: dispatch(getPuntosControl(match.params.EventoIndex)),
    eventUsersSend: dispatch(juecesEvento(match.params.EventoIndex)),
    eventResultsSend: dispatch(resultadosEventoNew(match.params.EventoIndex)),
  };
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventSubEvents: state.events.eventSubEvents,
    userID: state.atlete.userID,
    eventResults: state.events.eventResults,
    eventConfiguration: state.events.eventConfiguration,
    puntosControl: state.events.puntosControl,
    eventUsers: state.events.eventUsers,
  };
};

const AuthorizedUsersTimingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedUsersTimingContainerView);

export default AuthorizedUsersTimingContainer;
