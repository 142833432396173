import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import EditableField from "../../../../EditableField/EditableField";
import {
  getAllRegistries,
  updateAthleteResult,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import { getCheckChip } from "../../../../../data-store/actions/events-actions";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function Reads(props) {
  const dispatch = useDispatch();
  const [rowsNumber, setrowsNumber] = useState(25);

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const eventUsers = useSelector((state) => state.events.eventUsers);

  const eventResults = useSelector((state) => state.events.eventResults);
  const eventResultsRegistries = useSelector(
    (state) => state.events.eventResultsRegistries
  );
  const puntosControl = useSelector(
    (state) => state.events.puntosControl
  ).val();

  const classes = useStyles();

  function registriesList(eventResultsRegistriesData) {
    let registriesList = [];
    eventResultsRegistriesData.map((a) => {
      Object.values(a).map((b) => registriesList.push(b));
    });
    return registriesList;
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h3"} component="h2">
            Listado de registros del evento
          </Typography>{" "}
          <Typography gutterBottom variant={"h5"} component="h2">
            La lista muestra{" "}
            {rowsNumber === 1000000 ? "todos" : "los últimos " + rowsNumber}{" "}
            registros recibidos por chip o aplicación
          </Typography>{" "}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => setrowsNumber(25)}
            variant="contained"
            color="primary"
            disabled={rowsNumber === 25 ? true : false}
            style={{ margin: 10 }}
          >
            Mostrar 25
          </Button>
          <Button
            onClick={() => setrowsNumber(50)}
            variant="contained"
            color="primary"
            disabled={rowsNumber === 50 ? true : false}
            style={{ margin: 10 }}
          >
            Mostrar 50
          </Button>
          <Button
            onClick={() => setrowsNumber(100)}
            variant="contained"
            color="primary"
            disabled={rowsNumber === 100 ? true : false}
            style={{ margin: 10 }}
          >
            Mostrar 100
          </Button>
          <Button
            onClick={() => setrowsNumber(1000000)}
            variant="contained"
            color="primary"
            disabled={rowsNumber === 1000000 ? true : false}
            style={{ margin: 10 }}
          >
            Mostrar Todos
          </Button>
        </Grid>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Número</TableCell>
                <TableCell>Fecha Hora</TableCell>
                <TableCell>Registrado por</TableCell>
                <TableCell>Punto de Control</TableCell>
                <TableCell>Visible o Borrado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventResultsRegistries &&
                registriesList(eventResultsRegistries)
                  .sort(
                    (a, b) =>
                      new Date(b.fechaHora).getTime() -
                      new Date(a.fechaHora).getTime()
                  )
                  .slice(0, rowsNumber)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.numero}</TableCell>
                      <TableCell>{item.fechaHora}</TableCell>
                      <TableCell>
                        {eventUsers[item.competidorId]
                          ? eventUsers[item.competidorId].nombreJuez
                          : item.competidorId}
                      </TableCell>
                      <TableCell>
                        {
                          puntosControl["kPC_" + item.kPuntoControl]
                            .descripcionFinalPunto
                        }
                      </TableCell>
                      <TableCell>
                        {item.visibleOBorrado === 0 ? "Visible" : "Borrado"}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
