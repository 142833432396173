import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import EditableField from "../../../../EditableField/EditableField";
import {
  getAllRegistries,
  updateAthleteResult,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import { getCheckChip } from "../../../../../data-store/actions/events-actions";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ValidateReads(props) {
  const dispatch = useDispatch();
  const [registriesObject, setRegistriesObject] = useState(false);

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const eventResults = useSelector((state) => state.events.eventResults);
  const eventResultsRegistries = useSelector(
    (state) => state.events.eventResultsRegistries
  );

  const classes = useStyles();

  useEffect(() => {
    if (eventResults && eventResultsRegistries) {
      resultsList(eventResults, eventResultsRegistries);
    }
  }, [eventResults, eventResultsRegistries]);

  function findChipRegistry(eventResultsRegistriesData, numero, controlPoint) {
    console.log("revisar", numero, eventResultsRegistriesData[numero / 1]);

    let registriesNum = registriesList(eventResultsRegistriesData).filter(
      (a) => a.numero === numero
    );

    if (
      registriesNum &&
      Object.values(registriesNum).filter(
        (b) => b.kPuntoControl === controlPoint && b.competidorId === "Chip"
      ).length > 0
    ) {
      console.log("revisar", "si");
      return "si";
    } else {
      console.log("revisar", "no");
      return "no";
    }
  }

  function resultsList(eventResultsData, eventResultsRegistriesData) {
    let registriesList = [];
    let finishers = 0;
    let chipSi = 0;
    let chipNo = 0;

    eventResultsData
      .filter((a) => a.pG)
      .map((a) => {
        finishers++;
        let chip = "";
        if (findChipRegistry(eventResultsRegistriesData, a.num, 0) === "si") {
          chip = "si";
          chipSi++;
        } else {
          chip = "no";
          chipNo++;
        }
        //console.log(Object.values(eventResultsRegistriesData[a.num]));
        registriesList.push({
          ...a,
          chip: chip,
        });
      });
    setRegistriesObject({
      registriesList: registriesList,
      finishers: finishers,
      chipSi: chipSi,
      chipNo: chipNo,
    });
  }

  function registriesList(eventResultsRegistriesData) {
    let registriesList = [];
    eventResultsRegistriesData.map((a) => {
      Object.values(a).map((b) => registriesList.push(b));
    });
    return registriesList;
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h3"} component="h2">
            Lista de resultados para validar si la lectura fue por chip y por
            app
          </Typography>{" "}
        </Grid>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>Número></TableCell>
              <TableCell>Nombres</TableCell>
              <TableCell>Tiempo Oficial</TableCell>
              <TableCell>Chip</TableCell>
            </TableHead>
            <TableBody>
              {registriesObject &&
                registriesObject.registriesList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.num}</TableCell>
                    <TableCell>{item.nombre}</TableCell>
                    <TableCell>{item.to}</TableCell>
                    <TableCell>{item.chip}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableHead>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{registriesObject.finishers}</TableCell>
              <TableCell>
                {registriesObject.chipSi} / {registriesObject.chipNo}
              </TableCell>
            </TableHead>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
