import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import EditableField from "../../../../EditableField/EditableField";
import {
  updateAthleteResult,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { DescargarCertificadoReg } from "../../../reports/atleteDetail/certificadoLogic";
import BluetoothManager from "../../../../../services/BluetoothManager";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import { getCheckChip } from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function CheckChip(props) {
  const dispatch = useDispatch();
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const checkChip = useSelector((state) => state.events.checkChip);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const classes = useStyles();
  const eventParticipantsList = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );

  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  function getParticipantData(numero) {
    console.log(numero);
    if (
      Object.values(eventParticipantsList).filter((a) => a.numero === numero)
        .length > 0
    ) {
      const participant = Object.values(eventParticipantsList).filter(
        (a) => a.numero === numero
      )[0];
      return getModGenCat(eventSubEventsRegistry, participant);
    } else {
      return {
        numero: numero,
        nombres: "No encontrado",
        apellidos: "",
        genero: "NA",
        categoria: "na",
        modalidad: "NA",
      };
    }
  }

  function getModGenCat(subEventsRegistry, participant) {
    let mod = "";
    let cat = "";

    if (
      subEventsRegistry &&
      subEventsRegistry["modalidad_" + participant.modalidad]
    ) {
      mod =
        subEventsRegistry["modalidad_" + participant.modalidad]
          .descripcionModalidad;
    }

    if (
      subEventsRegistry &&
      subEventsRegistry["modalidad_" + participant.modalidad] &&
      subEventsRegistry["modalidad_" + participant.modalidad].ramaCat[
        "rama_" + participant.rama
      ] &&
      subEventsRegistry["modalidad_" + participant.modalidad].ramaCat[
        "rama_" + participant.rama
      ].categorias["categoria_" + participant.categoria]
    ) {
      cat =
        subEventsRegistry["modalidad_" + participant.modalidad].ramaCat[
          "rama_" + participant.rama
        ].categorias["categoria_" + participant.categoria].nombreCategoria;
    }

    return { ...participant, mod: mod, cat: cat, genero: participant.rama };
  }

  const checkChipOrder = checkChip
    ? Object.values(checkChip).sort((a, b) => b.timeStamp - a.timeStamp)
    : [];
  const firstItem =
    checkChipOrder && checkChipOrder.length > 0
      ? getParticipantData(checkChipOrder[0].numero)
      : false;
  const remainingItems =
    checkChipOrder && checkChipOrder.length > 1
      ? checkChipOrder.slice(1)
      : false;

  const backgroundImage =
    eventConfiguration &&
    eventConfiguration.timingConfig &&
    eventConfiguration.timingConfig.checkChipConfig &&
    eventConfiguration.timingConfig.checkChipConfig.checkChipImage
      ? eventConfiguration.timingConfig.checkChipConfig.checkChipImage
      : "";
  const checkChipFontColor =
    eventConfiguration &&
    eventConfiguration.timingConfig &&
    eventConfiguration.timingConfig.checkChipConfig &&
    eventConfiguration.timingConfig.checkChipConfig.checkChipFontColor
      ? eventConfiguration.timingConfig.checkChipConfig.checkChipFontColor
      : "#fff";
  const checkChipBackgroundColor =
    eventConfiguration &&
    eventConfiguration.timingConfig &&
    eventConfiguration.timingConfig.checkChipConfig &&
    eventConfiguration.timingConfig.checkChipConfig.checkChipImage
      ? eventConfiguration.timingConfig.checkChipConfig.checkChipBackgroundColor
      : "#fff";
  const checkChipPositionVertical =
    eventConfiguration &&
    eventConfiguration.timingConfig &&
    eventConfiguration.timingConfig.checkChipConfig &&
    eventConfiguration.timingConfig.checkChipConfig.checkChipPositionVertical
      ? eventConfiguration.timingConfig.checkChipConfig
          .checkChipPositionVertical
      : 0;
  const imageheight = 800 * 0.75;
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{ padding: 0, backgroundColor: checkChipBackgroundColor }}
    >
      <Grid item xs={12} sm={7}>
        {/* Primer elemento destacado */}
        <Box
          sx={{
            border: "3px solid #ef4122",
            borderRadius: "10px",
            padding: "15px",
            color: checkChipFontColor,
            textAlign: "center",
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: imageheight,
            width: (imageheight / 8) * 10,
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{
              marginTop: checkChipPositionVertical * (imageheight / 2),
            }}
          >
            {/* Número destacado */}
            <Grid item xs={12}>
              <Typography
                variant="h2"
                style={{
                  fontWeight: "bold",
                  color: checkChipFontColor,
                  textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                }}
              >
                Dorsal: {firstItem.numero}
              </Typography>
            </Grid>
            {/* Nombres y Apellidos */}
            <Grid item xs={12}>
              <Typography
                variant="h2"
                style={{
                  fontWeight: "600",
                  color: checkChipFontColor,
                  textShadow: "1px 1px 4px rgba(0, 0, 0, 0.7)",
                }}
              >
                {`${firstItem.nombres} ${firstItem.apellidos}`}
              </Typography>
            </Grid>
            {/* Datos secundarios */}
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{
                  color: checkChipFontColor,
                }}
              >
                Género: {firstItem.genero} | Categoría: {firstItem.cat} |
                Modalidad: {firstItem.mod}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        {/* Tabla para los demás elementos */}
        <TableContainer component={Paper} style={{ margin: "15px" }}>
          <Table size="small">
            <TableBody>
              {remainingItems &&
                remainingItems.length > 0 &&
                remainingItems.map((item1, index) => {
                  const item = getParticipantData(item1.numero);
                  return (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#ef4122",
                        }}
                      >
                        Número: {item.numero}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px", color: "#000" }}>
                        {item.nombres} {item.apellidos}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px", color: "#000" }}>
                        Género: {item.genero}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px", color: "#000" }}>
                        Categoría: {item.cat}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px", color: "#000" }}>
                        Modalidad: {item.mod}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
