import React from "react";
import { connect } from "react-redux";

import {
  eventParticipants,
  registryConfiguration,
  registryAutorizedUsers,
} from "../../../../data-store/actions/registry-actions";
import FormsContainerUI from "./Forms/FormsContainerUI";
import Convocatory from "../Convocatory/Convocatory";
import EventRegistryAuthForm from "./EventRegistryAUTH/EventRegistryAuthForm";

import RegistryConfigurationContainer from "./Configuration/RegistryConfigurationContainer";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import DashboardContainer from "./Dashboard/DashboardContainer";
import Income from "./ParticipantsLists/Income";
import Container from "@material-ui/core/Container";
import RegistryAuthorizationContainer from "./Configuration/RegistryAuthorizationContainer";
import EventRegistryContainer from "../../AuthorizedUsers/EventsConfig/EventRegistry/EventRegistryContainer";
import { Grid } from "@material-ui/core";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 30 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class RegistryContainerView extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    let arrayTabs = [];
    let contentTabs = [];

    let userProfile = null;
    var convocatoriaShow;
    var tabConfig;

    if (
      this.props.eventDetail != undefined &&
      this.props.eventDetail.convocatoria
    ) {
      arrayTabs.push(<Tab key="tab-Convocatoria" label="Convocatoria" />);
      contentTabs.push(
        <TabContainer key="contTab-Convocatoria">
          <Convocatory />
        </TabContainer>
      );
    }

    if (
      this.props.registryAutorizedUsers != undefined &&
      this.props.registryAutorizedUsers[this.props.userID] != undefined &&
      this.props.registryAutorizedUsers[this.props.userID].perfil != undefined
    ) {
      userProfile = this.props.registryAutorizedUsers[this.props.userID].perfil;
      if (
        userProfile.localeCompare("inscriptor") == 0 ||
        userProfile.localeCompare("MasterCliente") == 0 ||
        userProfile.localeCompare("MasterOrganizador") == 0 ||
        userProfile.localeCompare("master") == 0
      ) {
        arrayTabs.push(<Tab key="tab-Formulario" label="Formulario" />);
        contentTabs.push(
          <TabContainer key="contTab-Formulario">
            <Grid
              container
              direction="column"
              wrap="wrap"
              alignItems="center"
              alignContent="center"
            >
              <div id="topForm" style={{ height: "10px" }}></div>

              <Grid item xs={12} style={{ padding: "30px" }}>
                <EventRegistryAuthForm />
              </Grid>
            </Grid>
          </TabContainer>
        );
        arrayTabs.push(<Tab key="tab-Ingresos" label="Ingresos" />);
        contentTabs.push(
          <TabContainer key="contTab-Ingresos">
            <Income />
          </TabContainer>
        );
        arrayTabs.push(<Tab key="tab-Dashboard" label="Dashboard" />);
        contentTabs.push(
          <TabContainer key="contTab-Dashboard">
            <DashboardContainer />
          </TabContainer>
        );

        console.log(this.props.userID);
        console.log(this.props.registryAutorizedUsers);
      }
      // if (
      //   userProfile.localeCompare("Kits") == 0 ||
      //   userProfile.localeCompare("MasterOrganizador") == 0 ||
      //   userProfile.localeCompare("master") == 0
      // ) {
      //   arrayTabs.push(<Tab key="tab-Kits" label="Kits" />);
      //   contentTabs.push(
      //     <TabContainer key="contTab-Kits">
      //       <h5>Kits</h5>
      //     </TabContainer>
      //   );
      // }

      if (
        userProfile.localeCompare("MasterOrganizador") == 0 ||
        userProfile.localeCompare("master") == 0
      ) {
        arrayTabs.push(<Tab key="tab-Autorizaciones" label="Autorizaciones" />);
        contentTabs.push(
          <TabContainer key="contTab-Autorizaciones">
            <RegistryAuthorizationContainer />
          </TabContainer>
        );
        arrayTabs.push(<Tab key="tab-Configuracion" label="Configuración" />);
        contentTabs.push(
          <TabContainer key="contTab-Configuracion">
            <RegistryConfigurationContainer />
          </TabContainer>
        );
      }
    }

    let element;
    if (userProfile == null || userProfile == undefined) {
      return (element = (
        <div className="row">
          <div className="col s12 m8 offset-m2 l8 offset-l2">
            <h3>Cargando Datos ...</h3>
          </div>
        </div>
      ));
    } else {
      return (
        <Container>
          <div
            style={{
              position: "fixed",
              top: "64px",
              width: "100%",
              opacity: 1,
              left: "0",
              backgroundColor: "#ff4400",
              zIndex: "10000",
            }}
          >
            <Tabs
              className="z-depth-1 white-text"
              value={value}
              onChange={this.handleChange}
              scrollButtons="auto"
              variant="scrollable"
            >
              {arrayTabs}
            </Tabs>
          </div>
          {contentTabs[value]}
        </Container>
      );
    }
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  return {
    eventParticipants: dispatch(eventParticipants(match.params.EventoIndex)),
    registryConfiguration: dispatch(
      registryConfiguration(match.params.EventoIndex)
    ),
    registryAutorizedUsersSend: dispatch(
      registryAutorizedUsers(match.params.EventoIndex)
    ),
  };
};

const mapStateToProps = (state, { match }) => {
  return {
    eventDetail: state.events.eventDetail,
    eventConfiguration: state.events.eventConfiguration,
    eventConvocatory: state.events.eventConvocatory,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
    eventParticipants: state.registry.eventParticipants,
    registryConfiguration: state.registry.registryConfiguration,
    registryAutorizedUsers: state.registry.registryAutorizedUsers,
    userID: state.atlete.userID,
  };
};

const RegistryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistryContainerView);

export default RegistryContainer;
