import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import EditableField from "../../../../EditableField/EditableField";
import {
  getAllRegistries,
  updateAthleteResult,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import { getCheckChip } from "../../../../../data-store/actions/events-actions";
import Reads from "./Reads";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import ValidateReads from "./ValidateReads";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ReadsContainer(props) {
  const dispatch = useDispatch();
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const eventResults = useSelector((state) => state.events.eventResults);
  const eventResultsRegistries = useSelector(
    (state) => state.events.eventResultsRegistries
  );

  const classes = useStyles();

  useEffect(() => {
    if (!eventResultsRegistries) {
      dispatch(getAllRegistries("evento_" + eventDetail.iDEvento));
    }
  }, []);

  function handleChange(data) {
    console.log("dataTIMING", data);
    // if (Object.keys(data)[0] === "to") {
    //   data = { ...data, recPos: true };
    // } ya no recalculo posiciones por que se hace en local
    console.log("data", data);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "configuracion/timingConfig/",
        data
      )
    );
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Reads />

      {/* <Grid item xs={12} sm={12}>
        <SimpleDialogDemo
          buttonText={"Lista para validar chips vs app"}
          fullScreen={true}
        >      <ValidateReads />
        </SimpleDialogDemo>
      </Grid> */}
    </Grid>
  );
}
