import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import EditableField from "../../../../EditableField/EditableField";
import {
  updateAthleteResult,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import { Paper } from "@material-ui/core";
import { DescargarCertificadoReg } from "../../../reports/atleteDetail/certificadoLogic";
import BluetoothManager from "../../../../../services/BluetoothManager";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function TimingConfig(props) {
  const { business } = props;
  const [ipReader, setIpReader] = useState("192.168.2.2");

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const [advance, setAdvance] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const eventResults = useSelector((state) => state.events.eventResults);

  const classes = useStyles();
  function handleChange(data) {
    console.log("dataTIMING", data);
    // if (Object.keys(data)[0] === "to") {
    //   data = { ...data, recPos: true };
    // } ya no recalculo posiciones por que se hace en local
    console.log("data", data);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "configuracion/timingConfig/",
        data
      )
    );
  }

  // function deleteRegistries() {
  //   for (var i = 2001; i <= 2200; i++) {
  //     //    eventResults.map((i) => {
  //     dispatch(
  //       updateEventAttribute(
  //         "evento_" + eventDetail.iDEvento,
  //         "registrosJuez/",
  //         {
  //           ["num_" + i]: null,
  //         }
  //       )
  //     );
  //     // });
  //   }
  // }
  function calculatePositionsServer() {
    console.log("calculatePositions", eventResults[0].num);
    if (eventResults && eventResults[0] && eventResults[0].num) {
      dispatch(
        updateAthleteResult(
          "evento_" + eventDetail.iDEvento,
          eventResults[0].num,
          { recPos: true }
        )
      );
    }
  }
  function calculateAllData() {
    console.log("calculatePositions", eventResults[0].num);
    if (eventResults) {
      setDisabled(true);
      dispatch(
        updateEventAttribute(
          "evento_" + eventDetail.iDEvento,
          "configuracion/timingConfig/",
          { stopPositionCalculate: true }
        )
      );
      eventResults.forEach((item, index) =>
        setTimeout(() => {
          console.log("recalcular", item.num);

          dispatch(
            updateAthleteResult("evento_" + eventDetail.iDEvento, item.num, {
              recalcular: true,
            })
          );
          setAdvance(
            "Avance: " +
              Math.round((index / (eventResults.length - 1)) * 100) +
              "% Número:" +
              item.num
          );
          if (index === eventResults.length - 1) {
            setDisabled(false);
            setAdvance(
              "Terminado - CALCULO DE POSICIONES EN SERVIDOR DESHABILITADO"
            );
          }
        }, index * 250)
      );

      setDisabled(false);
      // dispatch(
      //   updateAthleteResult(
      //     "evento_" + eventDetail.iDEvento,
      //     eventResults[i].num,
      //     { recalcular: true }
      //   )
      // );
    }
  }

  // "certificateConfig": {
  //   "certificateFontColor": "#000000",
  //   "certificateImage": "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/fotosVarias%2F1674949287776%2FCertificado.jpeg?alt=media&token=e8fde583-47aa-49a4-927c-b7ee60ab62c4",
  //   "certificatePosition": 1,
  //   "certificateWhiteBack": true
  // }

  const certificateConfig =
    eventConfiguration.timingConfig &&
    eventConfiguration.timingConfig.certificateConfig
      ? eventConfiguration.timingConfig.certificateConfig
      : false;
  const resultadoJSON = {
    ab: 0,
    cat: "Libre",
    cg: 3,
    codCat: 1,
    codMod: 1,
    fhm: "2023-11-26 07:52:20.705",
    gen: "V",
    mod: 10,
    mp: 0,
    nombre: "NOMBRE DE PRUEBA",
    num: 100,
    pCat: 16,
    pG: 16,
    pR: 16,
    serie: 1,
    tc: "00:50:26",
    tcMS: "00:50:26.764",
    to: "00:50:31",
    toMS: "00:50:31.580",
  };
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={6}>
        {/* <BluetoothManager /> */}
        <Paper style={{ padding: 15 }}>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h3"} component="h2">
                Timing Config
              </Typography>{" "}
              <Typography gutterBottom variant={"h5"} component="h2">
                Para ajustar opcioes generales del Timing
              </Typography>{" "}
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <EditableField
                fieldObject={{
                  label: "Detener el calculo de posiciones en el servidor",
                  errorMessage: "Campo Requerido",
                  fieldName: "stopPositionCalculate",
                  type: "switch",
                  defaultValue:
                    eventConfiguration &&
                    eventConfiguration.timingConfig &&
                    eventConfiguration.timingConfig.stopPositionCalculate
                      ? eventConfiguration.timingConfig.stopPositionCalculate
                      : false,
                  required: true,
                  regex: "",
                  textSize: "h6",
                }}
                onSubmit={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                style={{ padding: 30, fontSize: 20, width: 300 }}
                onClick={() => calculatePositionsServer()}
                color="primary"
                variant="contained"
              >
                {" "}
                Calcular posiciones en el servidor{" "}
              </Button>
              {/* <Button
                style={{ padding: 30, fontSize: 20, width: 300 }}
                onClick={() => deleteRegistries()}
                color="primary"
                variant="contained"
              >
                {" "}
                Borrar registros Juez
              </Button> */}
              <Typography gutterBottom variant={"h6"} component="h2">
                NOTA: Este proceso va a actualizar las posiciones de todos los
                atletas en la base de datos del servidor, puede demorar algunos
                segundos dependiendo de la cantidad de atletas
              </Typography>{" "}
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography gutterBottom variant={"h5"} component="h2">
                Recalcular todos los atletas
              </Typography>{" "}
              <Button
                style={{ padding: 30, fontSize: 20, width: 300 }}
                onClick={() => calculateAllData()}
                color="primary"
                variant="contained"
                disabled={disabled}
              >
                {" "}
                Recalcular todos los atletas
              </Button>
              <Typography gutterBottom variant={"h6"} component="h2">
                NOTA: Este proceso puede demorar algo de tiempo con base a la
                cantidad de participantes. Va a calcular toda la data de los
                atletas y va a deshabilitar el cálculo de posiciones en el
                servidor.
              </Typography>{" "}
              <Typography gutterBottom variant={"h5"} component="h2">
                {advance}
              </Typography>{" "}
            </Grid>

            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </Paper>{" "}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper style={{ padding: 15 }}>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h3"} component="h2">
                Certificado de Resultados
              </Typography>{" "}
              <Typography gutterBottom variant={"h5"} component="h2">
                Para personalizar el certificado
              </Typography>{" "}
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <EditableField
                fieldObject={{
                  label: "Imagen para el certificado de resultados",
                  errorMessage: "Campo Requerido",
                  fieldName: "certificateConfig/certificateImage",
                  type: "photo",
                  defaultValue:
                    eventConfiguration &&
                    eventConfiguration.timingConfig &&
                    eventConfiguration.timingConfig.certificateConfig &&
                    eventConfiguration.timingConfig.certificateConfig
                      .certificateImage
                      ? eventConfiguration.timingConfig.certificateConfig
                          .certificateImage
                      : false,
                  required: true,
                  regex: "",
                  textSize: "h6",
                }}
                onSubmit={handleChange}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <EditableField
                fieldObject={{
                  label: "Color del texto",
                  errorMessage: "Campo Requerido",
                  fieldName: "certificateConfig/certificateFontColor",
                  type: "color",
                  defaultValue:
                    eventConfiguration &&
                    eventConfiguration.timingConfig &&
                    eventConfiguration.timingConfig.certificateConfig &&
                    eventConfiguration.timingConfig.certificateConfig
                      .certificateFontColor
                      ? eventConfiguration.timingConfig.certificateConfig
                          .certificateFontColor
                      : false,
                  required: true,
                  regex: "",
                  textSize: "h6",
                }}
                onSubmit={handleChange}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <EditableField
                fieldObject={{
                  label: "Ocultar Fondo Blanco",
                  errorMessage: "Campo Requerido",
                  fieldName: "certificateConfig/certificateWhiteBack",
                  type: "switch",
                  defaultValue:
                    eventConfiguration &&
                    eventConfiguration.timingConfig &&
                    eventConfiguration.timingConfig.certificateConfig &&
                    eventConfiguration.timingConfig.certificateConfig
                      .certificateWhiteBack
                      ? eventConfiguration.timingConfig.certificateConfig
                          .certificateWhiteBack
                      : false,
                  required: true,
                  regex: "",
                  textSize: "h6",
                }}
                onSubmit={handleChange}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <EditableField
                fieldObject={{
                  label:
                    "Posición horizontal del texto (0 izquierda, 1 centro, 2 derecha, admite decimales)",
                  errorMessage: "Campo Requerido",
                  fieldName: "certificateConfig/certificatePosition",
                  type: "text",
                  min: 0,
                  defaultValue:
                    eventConfiguration &&
                    eventConfiguration.timingConfig &&
                    eventConfiguration.timingConfig.certificateConfig &&
                    eventConfiguration.timingConfig.certificateConfig
                      .certificatePosition
                      ? eventConfiguration.timingConfig.certificateConfig
                          .certificatePosition
                      : 1,
                  required: true,
                  regex: "",
                  textSize: "h6",
                }}
                onSubmit={handleChange}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <EditableField
                fieldObject={{
                  label:
                    "Posición vertical del texto (0 arriba, 1 centro, 2 abajo, admite decimales)",
                  errorMessage: "Campo Requerido",
                  fieldName: "certificateConfig/certificatePositionVertical",
                  type: "text",
                  min: 0,
                  defaultValue:
                    eventConfiguration &&
                    eventConfiguration.timingConfig &&
                    eventConfiguration.timingConfig.certificateConfig &&
                    eventConfiguration.timingConfig.certificateConfig
                      .certificatePositionVertical
                      ? eventConfiguration.timingConfig.certificateConfig
                          .certificatePositionVertical
                      : 1,
                  required: true,
                  regex: "",
                  textSize: "h6",
                }}
                onSubmit={handleChange}
              />
            </Grid>

            <Button
              variant="contained"
              color="primary"
              style={{ width: "100%", padding: 30 }}
              onClick={() =>
                DescargarCertificadoReg(
                  eventDetail,
                  resultadoJSON,
                  2,
                  certificateConfig
                )
              }
            >
              DESCARGAR PRUEBA
            </Button>
          </Grid>
        </Paper>{" "}
      </Grid>
    </Grid>
  );
}
