import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { atletePhotosFunc } from "../../../../data-store/actions/atlete-actions";
import {
  athleteJuezRegistries,
  updateAthleteResult,
} from "../../../../data-store/actions/events-actions";

import EditableField from "../../../EditableField/EditableField";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import UpdateRegistryJuez from "./UpdateRegistryJuez";
import { Button } from "@material-ui/core";

export default function AtleteUpdateContainer(props) {
  const dispatch = useDispatch();
  const { resultado, eventDetail } = props;
  const userID = useSelector((state) => state.atlete.userID);
  const registryJuez = useSelector((state) => state.events.registryJuez);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventUsers = useSelector((state) => state.events.eventUsers);
  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );

  console.log("AtleteUpdateContainer");
  useEffect(() => {
    dispatch(
      athleteJuezRegistries("evento_" + eventDetail.iDEvento, resultado.num)
    );
  }, []);
  function recalculate() {
    dispatch(
      updateAthleteResult("evento_" + eventDetail.iDEvento, resultado.num, {
        recalcular: true,
      })
    );
  }
  function deleteAthlete() {
    dispatch(
      updateAthleteResult("evento_" + eventDetail.iDEvento, resultado.num, {
        borrado: resultado.borrado ? false : true,
        tc: "00:00:00",
        tcMS: "00:00:00.000",
        to: "00:00:00",
        toMS: "00:00:00.000",
        pG: 0,
        pR: 0,
        pCat: 0,
      })
    );
    setTimeout(() => {
      dispatch(
        updateAthleteResult("evento_" + eventDetail.iDEvento, resultado.num, {
          recalcular: true,
          //recPos: true, ya no recalculo por que esa parte ahora se hace local
        })
      );
    }, 1000);
  }

  function handleChange(data) {
    console.log("data", data);
    const modGenCat = data.modGenCat;
    if (modGenCat !== "") {
      dispatch(
        updateAthleteResult("evento_" + eventDetail.iDEvento, resultado.num, {
          codMod: modGenCat.codMod,
          codCat: modGenCat.codCat,
          mod: modGenCat.mod,
          cat: modGenCat.cat,
          gen: modGenCat.gen,
          recalcular: true,
        })
      );
    }
  }
  function handleChangeSerie(data) {
    console.log("dataserie", data);
    const serie = data.serie;
    if (modGenCat !== "") {
      dispatch(
        updateAthleteResult("evento_" + eventDetail.iDEvento, resultado.num, {
          serie: serie,
        })
      );
    }
  }
  function modGenCat(eventSubEvents) {
    console.log("eventSubEvents", eventSubEvents);
    let array = [];
    for (var mod in eventSubEvents) {
      const modalidad = eventSubEvents[mod];
      const ramas = modalidad.ramaCat;
      for (var gen in ramas) {
        const genero = ramas[gen];
        const categorias = genero.categorias;
        for (var cat in categorias) {
          const categoria = categorias[cat];
          array.push({
            label:
              modalidad.descripcionModalidad +
              " - " +
              genero.rama +
              " - " +
              categoria.nombreCategoria,
            value: {
              codMod: modalidad.codigoModalidad,
              mod: modalidad.modalidad,
              descripcionModalidad: modalidad.descripcionModalidad,
              gen: genero.rama,
              codCat: categoria.codigo,
              cat: categoria.nombreCategoria,
            },
          });
        }
      }
    }

    return array;
  }
  function startSerie(eventSubEvents, codMod) {
    console.log("eventSubEvents", eventSubEvents);
    let array = [];
    const starts = eventSubEvents["modalidad_" + codMod].hSalidas;
    for (var start in starts) {
      array.push({
        label: starts[start].descripcionSerie,
        value: starts[start].serie,
      });
    }

    return array;
  }

  return (
    <div>
      <div className="row">
        <div className="col s10 m10 l10">
          <h5>Editar Modalidad Género o Categoría</h5>
        </div>
        <div
          className="col s12 m6 offset-m3 l6 offset-l3"
          style={{
            border: "1px solid red",
            borderRadius: "20px",
            marginTop: "5px",
          }}
        >
          <EditableField
            fieldObject={{
              label: "Editar Modalidad - Género - Categoría",
              errorMessage: "Campo Requerido",
              fieldName: "modGenCat",
              type: "select",
              defaultValue: "",
              // {
              //   codMod: resultado.codMod,
              //   mod: resultado.mod,
              //   descripcionModalidad:
              //     eventSubEvents["modalidad_" + resultado.codMod]
              //       .descripcionModalidad,
              //   gen: resultado.gen,
              //   codCat: resultado.codCat,
              //   cat: resultado.categoria,
              // },
              required: true,
              regex: "",
              textSize: "h6",
              optionsArray: modGenCat(eventSubEvents),
            }}
            onSubmit={handleChange}
          />
          <EditableField
            fieldObject={{
              label: "Serie para su modalidad",
              errorMessage: "Campo Requerido",
              fieldName: "serie",
              type: "select",
              defaultValue: resultado.serie,
              // {
              //   codMod: resultado.codMod,
              //   mod: resultado.mod,
              //   descripcionModalidad:
              //     eventSubEvents["modalidad_" + resultado.codMod]
              //       .descripcionModalidad,
              //   gen: resultado.gen,
              //   codCat: resultado.codCat,
              //   cat: resultado.categoria,
              // },
              required: true,
              regex: "",
              textSize: "h6",
              optionsArray: startSerie(eventSubEvents, resultado.codMod),
            }}
            onSubmit={handleChangeSerie}
          />
        </div>
        <div className="col s10 m10 l10">
          <h5>Registros de tiempos</h5>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m8 offset-m2 z-depth-2">
          <table className="bordered centered" id="DetalleComp">
            <thead>
              <tr>
                <th scope="col">Usuario</th>
                <th scope="col">Fecha Hora</th>
                <th scope="col">Punto de Control</th>
                <th scope="col">Visible o Borrado</th>
                <th scope="col">Editar</th>
              </tr>
            </thead>
            <tbody id="ListaDetalles">
              {registryJuez &&
                registryJuez.map((registry, index) => (
                  <tr key={registry.index}>
                    <td>
                      {(eventUsers &&
                        eventUsers[registry.competidorId] &&
                        eventUsers[registry.competidorId].nombreJuez) ||
                        registry.competidorId}
                    </td>
                    <td>{registry.fechaHora}</td>
                    <td>
                      {(puntosControl &&
                        puntosControl["kPC_" + registry.kPuntoControl] &&
                        puntosControl["kPC_" + registry.kPuntoControl]
                          .descripcionFinalPunto +
                          " - " +
                          registry.kPuntoControl) ||
                        registry.kPuntoControl}
                    </td>
                    <td>
                      {registry.visibleOBorrado === 0 ? "Visible" : "Borrado"}
                    </td>
                    <td>
                      <SimpleDialogDemo buttonText={"Editar"}>
                        <UpdateRegistryJuez
                          registry={registry}
                          update={true}
                          eventDetail={eventDetail}
                        />
                      </SimpleDialogDemo>
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan="1">
                  <Button onClick={() => recalculate()}>Recalcular</Button>
                </td>
                <td colSpan="2">
                  <Button onClick={() => deleteAthlete()}>
                    {resultado.borrado
                      ? "Atleta Descalificado (Reversar?)"
                      : "Descalificar"}
                  </Button>
                </td>
                <td colSpan="2">
                  <SimpleDialogDemo buttonText={"Registrar un Control"}>
                    <UpdateRegistryJuez
                      registry={{
                        competidorId: userID,
                        fechaHora: eventDetail.fechaEvento + " 00:00:00.000",
                        kPuntoControl: 0,
                        lat: 1,
                        lng: 1,
                        visibleOBorrado: 0,
                        numero: resultado.num,
                        timeStamp: 0,
                      }}
                      update={false}
                      eventDetail={eventDetail}
                    />
                  </SimpleDialogDemo>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
