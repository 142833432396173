import React from "react";

import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { yellow } from "@material-ui/core/colors";

export default class PhotoEditorToPrintDobleToBike extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      anchoDiv: 0,
      altoDiv: 0,
      canvasKey: 0,
      cropping: false,
      image: null,
      anchoFoto: 0,
      altoFoto: 0,
      imgOrigen: "",
      imgOrigenGroup: "",
      photoOrientation: 1,
    };

    this.eventPhotoConfiguration = eventPhotoConfigurationDefault;
    if (this.props.currentEvent.photosConfig != undefined) {
      this.eventPhotoConfiguration = this.props.currentEvent.photosConfig;
    }
    this.anchoFoto = 0;
    this.altoFoto = 0;
    this.imgOrigen = 0;
    this.ajutar = 1; // para ajustar la imagen a un tama;o menor y evitar errores al descargar
    this.canvas = 0;
    this.ctx = 0;
    this.canvasGroup = 0;
    this.ctxGroup = 0;
    this.JCROPAPI = 0;
    this.number = 0;
  }

  //##################################################################
  componentDidMount() {
    this.canvasFunctionGroup(this.props.urlPhoto);
    //this.canvasFunction(this.props.urlPhoto);
  }

  componentDidUpdate(prevProps) {
    if (this.props.participant != prevProps.participant) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.canvasFunctionGroup(this.props.urlPhoto);
      //this.canvasFunction(this.props.urlPhoto);
    }
  }
  //##################################################################
  canvasFunctionGroup = (url) => {
    console.log("canvasFunction");
    this.canvas = document.getElementById(
      this.props.type + this.props.participant.raceNumber
    );
    this.ctx = this.canvas.getContext("2d");

    /* Enable Cross Origin Image Editing */
    this.imgOrigen = new Image();
    this.imgOrigen.crossOrigin = "";
    this.imgOrigen.src = url;
    this.imgOrigen.onload = () => {
      this.setState({ loading: false });

      this.anchoFoto = this.imgOrigen.width;
      this.altoFoto = this.imgOrigen.height;

      console.log(this.altoFoto);
      console.log(this.anchoFoto);

      // if (this.props.type === "raceNumber") {
      //   this.anchoFoto = 820 * (10 / 8);
      //   this.altoFoto = 820;

      //   this.canvas.width = this.anchoFoto;
      //   this.canvas.height = this.altoFoto;
      // } else {
      const margin = 1.02;
      const numbersOfEach = 2;
      const margintoCopy = 0.3;
      this.canvas.width =
        this.anchoFoto *
        this.props.currentEvent.raceNumberColumns *
        margin *
        1.02 *
        numbersOfEach *
        (1 + margintoCopy / 2);
      this.canvas.height =
        this.altoFoto * this.props.currentEvent.raceNumberRows * margin;
      //}

      this.number = this.props.currentEvent.initialNumber;
      this.backgroundColor = this.props.currentEvent.backgroundColor;
      this.vertical = true;

      this.ctx.fillStyle = this.backgroundColor || "#ffffff";
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

      let sideStart = 0;
      const add = this.anchoFoto + this.anchoFoto * margintoCopy;
      for (let e = 0; e < this.props.currentEvent.raceNumberColumns; e++) {
        for (let i = 0; i < this.props.currentEvent.raceNumberRows; i++) {
          this.ctx.drawImage(
            this.imgOrigen,
            e * this.anchoFoto * margin * 1.02 + sideStart,
            i * this.altoFoto * margin,
            this.anchoFoto,
            this.altoFoto
          );
          this.ctx.drawImage(
            this.imgOrigen,
            e * this.anchoFoto * margin * 1.02 + sideStart + add,
            i * this.altoFoto * margin,
            this.anchoFoto,
            this.altoFoto
          );
          this.racenumberFunc(
            this.number,
            e * this.anchoFoto * margin * 1.02 + sideStart,
            i * this.altoFoto * margin
          );
          this.racenumberFunc(
            this.number,
            e * this.anchoFoto * margin * 1.02 + sideStart + add,
            i * this.altoFoto * margin
          );

          this.number++;
        }
        sideStart = sideStart + add;
      }
    };
  };
  //##################################################################

  //##################################################################
  canvasFunction = (url) => {
    console.log("canvasFunction");
    this.canvas = document.getElementById(
      this.props.type + this.props.participant.raceNumber
    );
    this.ctx = this.canvas.getContext("2d");

    /* Enable Cross Origin Image Editing */
    this.imgOrigen = new Image();
    this.imgOrigen.crossOrigin = "";
    this.imgOrigen.src = url;
    this.imgOrigen.onload = () => {
      this.setState({ loading: false });

      this.anchoFoto = this.imgOrigen.width;
      this.altoFoto = this.imgOrigen.height;
      console.log(this.altoFoto);
      console.log(this.anchoFoto);

      // if (this.props.type === "raceNumber") {
      //   this.anchoFoto = 820 * (10 / 8);
      //   this.altoFoto = 820;

      //   this.canvas.width = this.anchoFoto;
      //   this.canvas.height = this.altoFoto;
      // } else {
      this.canvas.width = this.anchoFoto;
      this.canvas.height = this.altoFoto;
      //}

      this.ctx.drawImage(this.imgOrigen, 0, 0, this.anchoFoto, this.altoFoto);
      if (this.anchoFoto < this.altoFoto) {
        this.setState({ anchoDiv: "auto", altoDiv: "500px" });
      } else {
        this.setState({ anchoDiv: "500px", altoDiv: "auto" });
      }

      console.log("ancho" + this.anchoFoto + " altoFoto " + this.altoFoto);
      console.log(
        "anchodiv" + this.state.anchoDiv + " altodiv " + this.state.altoDiv
      );

      this.racenumberFunc();
    };
  };

  //##################################################################
  racenumberFunc = (number, x, y) => {
    let raceNumber = number;
    if (this.props.participant !== undefined) {
      if (
        this.props.participant.raceNumber == undefined ||
        this.props.participant.raceNumber == null
      ) {
        raceNumber = "N/A";
      }

      this.insertaRaceNumber(
        raceNumber,
        this.props.participant.athleteName,
        this.props.participant.goalTime,
        this.props.currentEvent.distance,
        this.props.currentEvent,
        x,
        y
      );
      this.passCanvasToImg();
    }
  };
  //##################################################################
  //##################################################################
  insertaRaceNumber = (
    raceNumber,
    nombre,
    tiempo,
    distancia,
    currentEvent,
    x,
    y
  ) => {
    const recuadro = this.altoFoto / 2 - 210;

    if (!currentEvent.raceNumberWhiteBack) {
      this.ctx.fillStyle = "#ffcdd2";
      this.ctx.globalAlpha = 0.5;
      this.ctx.fillRect(2, recuadro + 2, this.anchoFoto, 420);
      this.ctx.fillStyle = "#FFFFFF";
      this.ctx.fillRect(0, recuadro, this.anchoFoto, 420);
    }

    const raceNumberTest = this.props.participant.raceNumberTest || raceNumber;
    const color = currentEvent.raceNumberFontColor || "#000000";
    const colorBorder = currentEvent.raceNumberFontColorBorder || "#000000";
    const fontSize = currentEvent.raceNumberFontSize || 340;
    const vertical = currentEvent.raceNumberVertical || 1;
    const horizontal = currentEvent.raceNumberHorizontal || 1;
    const fontFamily = currentEvent.fontFamily || "Forza";
    const fontType = currentEvent.fontType || "normal";
    this.ctx.globalAlpha = 1;

    this.dibujarTextoCentrado(
      raceNumberTest,
      fontSize,
      (recuadro + 280) * vertical,
      fontFamily,
      color,
      horizontal,
      colorBorder,
      fontType,
      x,
      y
    );
    // this.dibujarTextoCentrado(
    //   nombre.toUpperCase(),
    //   40,
    //   recuadro + 340,
    //   "Forza",
    //   color,
    //   1
    // );
    if (
      (currentEvent.eventType && currentEvent.eventType === "byTime") ||
      tiempo === undefined
    ) {
    } else {
      this.dibujarTextoCentrado(
        `Voy por ${distancia} kilómetros en ${tiempo}`,
        30,
        recuadro + 390,
        "MavenPro-VariableFont_wght",
        color,
        1
      );
    }
  };
  //##################################################################
  //##################################################################

  //##################################################################
  dibujarTexto = (titulo, texto, orden, fuente, alto, tipofuente) => {
    const centroDatos = this.anchoFoto / 2;
    const altoDatos = alto;
    var entrelinea = 42;
    var espacioCentro = 10;

    this.ctx.font = "bold " + fuente + "px " + tipofuente;
    this.ctx.fillStyle = "#212121";
    var anchoTexto = this.ctx.measureText(titulo).width;
    var X = centroDatos - anchoTexto;
    var Y = altoDatos + orden * entrelinea;
    this.ctx.fillText(titulo, X, Y);
    this.ctx.font = fuente + "px " + tipofuente;
    this.ctx.fillText(texto, centroDatos + espacioCentro, Y);
  };

  //##################################################################
  dibujarTextoCentrado = (
    texto,
    fuente,
    textoY,
    tipofuente,
    color,
    lugarHorizontal,
    colorBorder,
    fontType,
    x,
    y
  ) => {
    this.ctx.font = fontType + " " + fuente + "px " + tipofuente;
    var i = this.ctx.measureText(texto).width;

    var textoX = (this.anchoFoto / 2) * lugarHorizontal - i / 2;
    this.ctx.fillStyle = colorBorder;
    this.ctx.fillText(texto, textoX + 4 + x, textoY + 4 + yellow);
    this.ctx.fillStyle = color;
    this.ctx.strokeStyle = colorBorder;
    this.ctx.lineWidth = 15;
    this.ctx.strokeText(texto, textoX + x, textoY + y);
    this.ctx.fillText(texto, textoX + x, textoY + y);
  };
  //##################################################################

  descargaFinal = () => {
    // esta es la parte que descarga en about blank el texto dice Descarga opcion 3
    console.log("descarga");

    // var dato = this.canvas.toDataURL("image/jpeg");
    // dato = dato.replace("image/jpeg", "image/octet-stream");
    // document.location.href = dato;
    const desde = this.number - 60;
    var link = document.createElement("a");
    link.download = "Dorsales desde el número " + desde + ".jpeg";
    link.href = document
      .getElementById(this.props.type + this.props.participant.raceNumber)
      .toDataURL("image/jpeg");
    link.click();
  };
  //##################################################################
  passCanvasToImg = () => {
    this.setState({
      imgOrigen: document
        .getElementById(this.props.type + this.props.participant.raceNumber)
        .toDataURL("image/jpeg"),
    });
  };
  passCanvasGroupToImg = () => {
    this.setState({
      imgOrigenGroup: document
        .getElementById(
          this.props.type + this.props.participant.raceNumber + "group"
        )
        .toDataURL("image/jpeg"),
    });
  };

  insertaTexto = (textoParams) => {
    // txt = texto a insertar
    // anchoPorcentaje = ancho del texto en relacion al ancho de la foto
    // x = inicio del texto segun la alineacion
    // y = inicio del texto de forma vertical
    // colorTexto
    // AjusteFuente= para estirar la fuente o mantener igual (1) segun dise;o valor de 1 en adelante.
    // sombra= 1 para colocar sobra negra al texto, 0 para no colocar
    // Alineacion = puede ser 'start', 'end', 'center'

    //ejm de coomo llamar la funcion
    // this.insertaTexto(textoParams);

    // var textoParams = {
    //     txt:this.props.resultado.nombre,
    //     anchoPorcentajePortrait:0.7,
    //     anchoPorcentajeLandscape:0.4,
    //     anchoPorcentajeSquare:0.5,
    //     x : this.anchoFoto/2,
    //     y:this.altoFoto/2,
    //     colorTexto:'white',
    //     AjusteFuente:2,
    //     sombra:true,
    //     Alineacion:'center'
    // }

    var fuente = 0;
    var i = 0;
    var anchoTextoFinal;
    if (this.state.photoOrientation == 1) {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajePortrait;
    } else if (this.state.photoOrientation == 2) {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajeLandscape;
    } else {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajeSquare;
    }
    this.ctx.textAlign = textoParams.Alineacion;

    while (i < anchoTextoFinal) {
      fuente++;
      console.log(fuente);
      this.ctx.font = fuente + "px " + textoParams.font;
      i = this.ctx.measureText(textoParams.txt).width;
    }

    // para ajustar el alto del texto sin afectar el ancho maximo seleccioando
    this.ctx.font =
      fuente * textoParams.AjusteFuente + "px " + textoParams.font;

    // para colocar el texto atras como sombra
    if (textoParams.sombra) {
      this.ctx.globalAlpha = 0.5;
      this.ctx.fillStyle = "black";
      this.ctx.fillText(
        textoParams.txt,
        textoParams.x + 2,
        textoParams.y + 2,
        anchoTextoFinal
      );
      this.ctx.globalAlpha = 1;
    }
    this.ctx.fillStyle = textoParams.colorTexto;
    this.ctx.fillText(
      textoParams.txt,
      textoParams.x,
      textoParams.y,
      anchoTextoFinal
    );
    //    this.insertaLogos();

    return fuente;
  };
  //##################################################################
  render() {
    let loading = "";
    if (this.state.loading) {
      loading = (
        <div id="LoadingFoto" className="preloader-wrapper big active">
          <div className="spinner-layer spinner-blue-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    } else {
      loading = "";
    }

    return (
      <div>
        <Grid container spacing={3} direction="row" alignItems="center">
          <Grid xs={12}>
            {this.props.type == "raceNumber" && (
              <Typography gutterBottom variant="h6" component="h5">
                Número de competencia
              </Typography>
            )}
            {this.props.type == "frameImage" && (
              <Typography gutterBottom variant="h6" component="h5">
                Marco del evento
              </Typography>
            )}
            {this.props.type == "medal" && (
              <Typography gutterBottom variant="h6" component="h5">
                Medalla
              </Typography>
            )}
            {this.props.type == "goalTime" && (
              <Typography gutterBottom variant="h6" component="h5">
                Tiempo objetivo
              </Typography>
            )}
            {this.props.type == "finishTime" && (
              <Typography gutterBottom variant="h6" component="h5">
                Tiempo Oficial
              </Typography>
            )}
          </Grid>

          <Grid
            xs={12}
            onClick={() => this.descargaFinal()}
            style={{ cursor: "poiter" }}
          >
            {loading}
            <img
              id={"imagen"}
              src={this.state.imgOrigen}
              style={{
                maxWidth: this.props.size || "200px",
                maxHeight: this.props.size || "200px",
              }}
            />
            <img
              id={"imagenGroup"}
              src={this.state.imgOrigenGroup}
              style={{
                maxWidth: this.props.size || "200px",
                maxHeight: this.props.size || "200px",
              }}
            />
          </Grid>
          <Grid xs={4} style={{ cursor: "poiter" }}>
            <Button
              onClick={() => this.descargaFinal()}
              variant="contained"
              color="primary"
            >
              Descargar
            </Button>
          </Grid>
        </Grid>

        <canvas
          className="FotoEditar1"
          id={this.props.type + this.props.participant.raceNumber}
          style={{ display: "none" }}
        ></canvas>
        <canvas
          className="FotoEditar1"
          id={this.props.type + this.props.participant.raceNumber + "group"}
          style={{ display: "none" }}
        ></canvas>
        <span style={{ fontFamily: "MavenPro-VariableFont_wght" }}>.</span>
        <span style={{ fontFamily: "digital-7Final" }}>.</span>
      </div>
    );
  }
}

const eventPhotoConfigurationDefault = {
  logos: [
    {
      anchoLogo: 0.25,
      codLogo: 2,
      descripcion: "Logo Dashport",
      margen: 15,
      posicion: 4,
      rutaLogo:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73",
    },
  ],
  marcos: [
    {
      codMarco: 1,
      descripcion: "Marco Blanco",
      rutaMarco:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeBlancoRecto.png?alt=media&token=4b64abde-f3ef-476c-9dbf-b2d3f30350be",
      tipo: "Estandar",
    },
    {
      codMarco: 2,
      descripcion: "Marco Negro",
      rutaMarco:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeNegroRecto.png?alt=media&token=7c73c524-8033-4285-be82-8b818b1cf21e",
      tipo: "Estandar",
    },
  ],
};
