import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";
import { store } from "../configureStore";
import { fecha } from "../../services/utils";
import { showSnackbar } from "./organizers-actions";
import {
  calculateAthlete,
  calculatePositions,
} from "../../components/pages/AuthorizedUsers/Timing/TimingLogic/TimingUtils.jsx";

export function initEvents() {
  var ref = fb.db.ref(fb.EventosLista);
  return (dispatch) => {
    ref
      .orderByChild("iDEvento")
      .startAt(0)
      .on("value", (snapshot) => {
        dispatch({
          type: types.INIT_EVENTS,
          events: snapshot.val(),
        });
      });
  };
}
//#################################################################
export function createPublicEvent(data, userID, atleteAccount) {
  var ref = fb.db.ref(fb.EventosLista);

  const defaultObject = {
    detalleEvento: {
      ciudadEvento: data.ciudadEvento || "",
      convocatoria: false,
      cronometrajeDisponible: false,
      descripcionEvento: data.descripcionEvento || "",
      eventoDisponible: data.eventoDisponible || 0,
      fechaEvento: data.fechaEvento || "2021-12-31",
      horaEvento: data.horaEvento || "05:00:00",
      iDEvento: 0,
      imagenEvento: "",
      logoEvento:
        data.logoEvento ||
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/FotosFondo%2FfotoPrincipal_Dashport_bajaResolucion.jpg?alt=media&token=6042c273-cbab-4cf3-bba3-83a350f8ff4d",
      lugarEvento: data.lugarEvento || "",
      nombreEvento: data.nombreEvento || "",
      nombreEventoCorto: data.nombreEvento || "",
      organizador: {
        colorOrganizador: data.colorOrganizador || "#000000",
        linkAvisoPrivacidad: "https://dashport.run/politicas-privacidad/",
        linkEvento: "",
        logoOrganizador:
          data.logoOrganizador ||
          "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73",
        nombreOrganizador: "Dashport",
        telefonoContacto: 9997405097,
        webOrganizador: "https://dashport.run/",
      },
      paisEvento: data.paisEvento || "",
      tipoDeEvento: "Carrera",
      userCreate: userID,
    },
    configuracion: {
      cronometrajeDisponible: true,
      eventoPublico: true,
      fotografiasDisponibles: true,
      inscripcionesDisponibles: true,
      resultadosDisponibles: true,
      tiempoRealDisponible: true,
    },
    inscripcionesConfiguracion: {
      configuracion: {
        categoriasInferiores: false,
        codigoQR: true,
        deslindeInMail: false,
        edadActual: true,
        entregaKits: {
          available: false,
          items: {
            item_1: {
              code: "item_1",
              description: "entrega de kit",
              itemForm: [
                {
                  anchoCampo: "s12 m12 l12",
                  campo: 10,
                  etiqueta: "Documento mostrado",
                  iDCampo: "documento",
                  longitud: 100,
                  observacion: "",
                  tipoCampo: "text",
                  validacion: "",
                  valor: "",
                },
              ],
              itemOrder: "insc",
              name: "Entrega del kit completo",
            },
          },
        },
        formasDePago: {
          codigoInscripcion: false,
          comisionGlobal: atleteAccount.comision || 0.07,
          conektaCard: {
            comision: atleteAccount.comision || 0.07,
            disponible: true,
            montoMaximo: 3000,
            tipoComision: 1,
          },
          conektaOxxoPay: {
            comision: atleteAccount.comision || 0.07,
            disponible: true,
            tipoComision: 1,
          },
        },
        rutaDetalleInscrito: "https://dashport.run/detalle-inscrito",
        separarApellidos: false,
      },
      gruposFormularios: {
        general: [
          {
            campo: 1,
            etiqueta: "Campo de prueba",
            iDCampo: "pordefecto",
            longitud: 100,
            observacion: "",
            tipoCampo: "text",
            validacion: "Requerido",
            valor: "",
          },
        ],
      },
      formulario: [
        {
          campo: 1,
          etiqueta: "Campo de prueba",
          iDCampo: "pordefecto",
          longitud: 100,
          observacion: "",
          tipoCampo: "text",
          validacion: "Requerido",
          valor: "",
        },
      ],
      iDEvento: 200,
    },
    oganizadoresEvento: {
      [userID]: {
        accesoPermitido: true,
        correo: atleteAccount.correo,
        nombre: atleteAccount.nombres + " " + atleteAccount.apellidos,
        perfil: "MasterOrganizador",
      },
      RHlMQSMvpPTBySpb1m32F196xnk1: {
        accesoPermitido: true,
        correo: "eduardomadrid84@yahoo.com",
        nombre: "Eduardo Madrid Alvarez de Lugo",
        perfil: "master",
      },
      McqSKr5W0nPVfvbyJuKNcWvKpHK2: {
        accesoPermitido: true,
        correo: "info@dashport.run",
        nombre: "Info Dashport",
        perfil: "master",
      },
    },
    puntosDeControl: {
      kPC_0: {
        descripcionFinalPunto: "Meta del evento",
        flotante: false,
        kNroPunto: 0,
        lat: 1,
        lng: 1,
        modalidadesPuntoDeControl: {},
      },
    },
    autorizacionOrganizador: {
      "-MNOp1uPLwY0praJPjmv": {
        correo: atleteAccount.correo,
        fechaAutoriza: "2020/11/30 09:45:04",
        perfil: "MasterOrganizador",
        status: "ok",
        userIdAuth: userID,
      },
      "-MNOp1uPLwY0praJPjmv1": {
        correo: "eduardomadrid84@yahoo.com",
        fechaAutoriza: "2020/11/30 09:45:04",
        perfil: "master",
        status: "ok",
        userIdAuth: userID,
      },
      "-MNOp1uPLwY0praJPjmv2": {
        correo: "info@dashport.run",
        fechaAutoriza: "2020/11/30 09:45:04",
        perfil: "master",
        status: "ok",
        userIdAuth: userID,
      },
    },
    modalidades: {
      modalidad_1: {
        codigoModalidad: 1,
        descripcionModalidad: "Modalidad DEMO",
        hSalidas: {
          serie_1: {
            descripcionSerie: "Salida Modalidad 1",
            hS: "0000-00-00 00:00:00",
            hsTimeStamp: 0,
            serie: 1,
          },
        },
        modalidad: 10,
        ramaCat: {
          rama_F: {
            categorias: {
              categoria_1: {
                absolutos: false,
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 1,
                edadDesde: 18,
                edadHasta: 29,
                inscritos: 0,
                serie: 1,
                minimoParticipantes: 10,
                nombreCategoria: "Libre",
                precioKey: "precio_1",
              },
              categoria_2: {
                absolutos: false,
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 2,
                edadDesde: 30,
                edadHasta: 39,
                inscritos: 0,
                minimoParticipantes: 10,
                nombreCategoria: "Sub Master",
                precioKey: "precio_1",
              },
              categoria_3: {
                absolutos: false,
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 3,
                edadDesde: 40,
                edadHasta: 49,
                inscritos: 0,
                minimoParticipantes: 10,
                nombreCategoria: "Master",
                precioKey: "precio_1",
              },
              categoria_4: {
                absolutos: false,
                camposAdicionalesKey: "campos_1",
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 4,
                edadDesde: 0,
                edadHasta: 200,
                inscritos: 0,
                minimoParticipantes: 10,
                nombreCategoria: "Relevos",
                precioKey: "precio_1",
              },
            },
            rama: "F",
          },
          rama_V: {
            categorias: {
              categoria_1: {
                absolutos: false,
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 1,
                edadDesde: 18,
                edadHasta: 29,
                inscritos: 0,
                serie: 1,
                minimoParticipantes: 10,
                nombreCategoria: "Libre",
                precioKey: "precio_1",
              },
              categoria_2: {
                absolutos: false,
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 2,
                edadDesde: 30,
                edadHasta: 39,
                inscritos: 0,
                minimoParticipantes: 10,
                nombreCategoria: "Sub Master",
                precioKey: "precio_1",
              },
              categoria_3: {
                absolutos: false,
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 3,
                edadDesde: 40,
                edadHasta: 49,
                inscritos: 0,
                minimoParticipantes: 10,
                nombreCategoria: "Master",
                precioKey: "precio_1",
              },
              categoria_4: {
                absolutos: false,
                camposAdicionalesKey: "campos_1",
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 4,
                edadDesde: 0,
                edadHasta: 200,
                inscritos: 0,
                minimoParticipantes: 10,
                nombreCategoria: "Relevos",
                precioKey: "precio_1",
              },
            },
            rama: "V",
          },
        },
      },
    },
    modalidadesInscripcion: {
      modalidad_1: {
        codigoModalidad: 1,
        configModalidad: {
          camposAdicionalesModalidad: {},
          folios: {
            capacidad: 1000,
            desde: 1,
            foliosNumerados: true,
            hasta: 1000,
            inscritos: 0,
          },
          precio: {
            preventa_1: {
              desde: "2010-01-01 00:00:00",
              disponible: true,
              hasta: "2025-01-19 23:59:59",
              precio_1: 150,
            },
          },
          productosAdicionales: {},
        },
        descripcionModalidad: "Nombre de distancia",
        hSalidas: {
          serie_1: {
            descripcionSerie: "Salida Modalidad 1",
            hS: "0000-00-00 00:00:00",
            hsTimeStamp: 0,
            serie: 1,
          },
        },
        modalidad: 10,
        ramaCat: {
          rama_F: {
            categorias: {
              categoria_1: {
                absolutos: false,
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 1,
                edadDesde: 18,
                edadHasta: 29,
                inscritos: 0,
                serie: 1,
                minimoParticipantes: 10,
                nombreCategoria: "Libre",
                precioKey: "precio_1",
              },
            },
            rama: "F",
          },
          rama_V: {
            categorias: {
              categoria_1: {
                absolutos: false,
                cantidadGanadores: 3,
                capacidad: 200,
                codigo: 1,
                edadDesde: 18,
                edadHasta: 29,
                inscritos: 0,
                serie: 1,
                minimoParticipantes: 10,
                nombreCategoria: "Libre",
                precioKey: "precio_1",
              },
            },
            rama: "V",
          },
        },
      },
    },
  };
  console.log(defaultObject);

  return (dispatch) => {
    ref.once("value", (snapshot) => {
      var maxEventId = Object.values(snapshot.val()).reduce((prev, current) =>
        prev.iDEvento > current.iDEvento ? prev : current
      );
      const iDEvento = maxEventId.iDEvento + 1;
      console.log("maxEventId", maxEventId);
      defaultObject.detalleEvento.iDEvento = iDEvento;
      return createEvent("evento_" + iDEvento, defaultObject);
    });
  };
}
//#################################################################
export function createEvent(eventoIndex, data) {
  console.log("Guardar datos de evento");
  if (eventoIndex) {
    // referencia a la base de datos

    console.log(data);
    return fb.db
      .ref(`${fb.EventosCompletos}/${eventoIndex}/`)
      .set(data)
      .then(function() {
        console.log("Datos Guardados");
        return fb.db
          .ref(`${fb.EventosLista}/${eventoIndex}/`)
          .set(data.detalleEvento);
      })
      .then(function() {
        console.log("Datos Guardados");
        // dispatch(
        //   createEventLog(
        //     eventoIndex,
        //     data,
        //     `${fb.EventosCompletos}/${eventoIndex}/`
        //   )
        // );
      })
      .catch(function(error) {
        console.log("Error" + error);
      });
  }
}
//#################################################################
export function updateEventAttribute(eventoIndex, route, data) {
  console.log("Guardar datos de evento");

  if (eventoIndex) {
    // referencia a la base de datos
    var updates = {};

    if (route.indexOf("detalleEvento") >= 0) {
      const routeNew = route.replace("detalleEvento/", "");
      updates[
        `${fb.EventosLista}/${eventoIndex}/${routeNew}/${Object.keys(data)[0]}/`
      ] = Object.values(data)[0];
    }

    updates[
      `${fb.EventosCompletos}${eventoIndex}/${route}${Object.keys(data)[0]}/`
    ] = Object.values(data)[0];

    console.log(data);
    console.log(route);
    console.log(eventoIndex);
    console.log("dataTIMING", updates);

    return (dispatch) => {
      return fb.db
        .ref()
        .update(updates)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(createEventLog(eventoIndex, data, route));
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    };
  }
}
//#################################################################
export function updateEventObject(eventoIndex, route, data) {
  console.log("Guardar datos de evento");

  if (eventoIndex) {
    // referencia a la base de datos
    var updates = {};

    updates[`${fb.EventosCompletos}/${eventoIndex}/${route}`] = data;

    return (dispatch) => {
      return fb.db
        .ref()
        .update(updates)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(createEventLog(eventoIndex, data, route));
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    };
  }
}
//#################################################################
export function createEventAttribute(eventoIndex, route, data) {
  console.log("Guardar datos");

  if (eventoIndex) {
    // referencia a la base de datos
    const finalRoute = `${fb.EventosCompletos}/${eventoIndex}/${route}/`;

    return (dispatch) => {
      return fb.db
        .ref(finalRoute)
        .set(data)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(createEventLog(eventoIndex, data, route));
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    };
  }
}
//#################################################################
export function createSubEvent(
  eventoIndex,
  data,
  userID,
  eventSubEventsRegistry
) {
  var maxModId = Object.values(eventSubEventsRegistry).reduce((prev, current) =>
    prev.codigoModalidad > current.codigoModalidad ? prev : current
  );
  const codigoModalidad = maxModId.codigoModalidad + 1;

  const camposAdicionalesModalidad =
    Object.values(eventSubEventsRegistry)[0].configModalidad
      .camposAdicionalesModalidad || null;
  const defaultObject = {
    codigoModalidad: codigoModalidad,
    userCreate: userID,
    hSalidas: {
      serie_1: {
        descripcionSerie: "Salida Modalidad 1",
        hS: "0000-00-00 00:00:00",
        hsTimeStamp: 0,
        serie: 1,
      },
    },
    configModalidad: {
      camposAdicionalesModalidad: camposAdicionalesModalidad,
      folios: {
        capacidad: 1000,
        desde: data["configModalidad/folios/desde"] || 1,
        foliosNumerados: true,
        hasta: data["configModalidad/folios/hasta"] || 1000,
        inscritos: data["configModalidad/folios/inscritos"] || 0,
      },
      precio: {
        preventa_1: {
          desde: "2010-01-01 00:00:00",
          disponible: true,
          hasta: "2025-01-19 23:59:59",
          precio_1: data["configModalidad/precio/preventa_1/precio_1"] || 150,
        },
      },
    },
    descripcionModalidad: data.descripcionModalidad || "Nombre Distancia",
    modalidad: data.modalidad || 5,
    ramaCat: {
      rama_F: {
        categorias: {
          categoria_1: {
            absolutos: false,
            cantidadGanadores: 3,
            capacidad: 200,
            codigo: 1,
            edadDesde: 18,
            edadHasta: 29,
            inscritos: 0,
            serie: 1,
            minimoParticipantes: 10,
            nombreCategoria: "Libre",
            precioKey: "precio_1",
          },
        },
        rama: "F",
      },
      rama_V: {
        categorias: {
          categoria_1: {
            absolutos: false,
            cantidadGanadores: 3,
            capacidad: 200,
            codigo: 1,
            edadDesde: 18,
            edadHasta: 29,
            inscritos: 0,
            serie: 1,
            minimoParticipantes: 10,
            nombreCategoria: "Libre",
            precioKey: "precio_1",
          },
        },
        rama: "V",
      },
    },
  };
  console.log(defaultObject);

  const route = "modalidadesInscripcion/modalidad_" + codigoModalidad;

  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (eventoIndex) {
      // referencia a la base de datos

      console.log(data);
      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(defaultObject)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            createEventLog(eventoIndex, { action: "createSubEvent" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function createSubEventTiming(
  eventoIndex,
  data,
  userID,
  eventSubEventsTiming
) {
  var maxModId = Object.values(eventSubEventsTiming).reduce((prev, current) =>
    prev.codigoModalidad > current.codigoModalidad ? prev : current
  );
  const codigoModalidad = maxModId.codigoModalidad + 1;

  const defaultObject = {
    codigoModalidad: codigoModalidad,
    userCreate: userID,
    descripcionModalidad: data.descripcionModalidad || "Nombre Distancia",
    modalidad: data.modalidad || 5,
    hSalidas: {
      serie_1: {
        descripcionSerie: "Salida Modalidad 1",
        hS: "0000-00-00 00:00:00",
        hsTimeStamp: 0,
        serie: 1,
      },
    },
    ramaCat: {
      rama_F: {
        categorias: {
          categoria_1: {
            absolutos: false,
            cantidadGanadores: 3,
            capacidad: 200,
            codigo: 1,
            edadDesde: 18,
            edadHasta: 29,
            inscritos: 0,
            serie: 1,
            minimoParticipantes: 10,
            nombreCategoria: "Libre",
            precioKey: "precio_1",
          },
        },
        rama: "F",
      },
      rama_V: {
        categorias: {
          categoria_1: {
            absolutos: false,
            cantidadGanadores: 3,
            capacidad: 200,
            codigo: 1,
            edadDesde: 18,
            edadHasta: 29,
            inscritos: 0,
            serie: 1,
            minimoParticipantes: 10,
            nombreCategoria: "Libre",
            precioKey: "precio_1",
          },
        },
        rama: "V",
      },
    },
  };
  console.log(defaultObject);

  const route = "modalidades/modalidad_" + codigoModalidad;

  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (eventoIndex) {
      // referencia a la base de datos

      console.log(data);
      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(defaultObject)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            createEventLog(eventoIndex, { action: "createSubEvent" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function createControlPointTiming(eventoIndex, userID, controlPoints) {
  var maxModId = Object.values(controlPoints).reduce((prev, current) =>
    prev.kNroPunto > current.kNroPunto ? prev : current
  );
  const kNroPunto = maxModId.kNroPunto + 1;

  const defaultObject = {
    descripcionFinalPunto: "Control Número " + kNroPunto,
    flotante: false,
    kNroPunto: kNroPunto,
    lat: 1,
    lng: 1,
    modalidadesPuntoDeControl: {},
  };

  const route = "puntosDeControl/kPC_" + kNroPunto;
  return (dispatch) => {
    console.log("Punto de control creado");
    if (eventoIndex) {
      // referencia a la base de datos

      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(defaultObject)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            showSnackbar(true, "Punto de control creado correctamente ", 5000)
          );

          dispatch(
            createEventLog(eventoIndex, { action: "createSubEvent" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function createControlPointLap(
  eventoIndex,
  userID,
  controlPointIndex,
  controlPoints,
  distanceIndex
) {
  const laps =
    controlPoints["kPC_" + controlPointIndex].modalidadesPuntoDeControl[
      "modalidad_" + distanceIndex
    ].laps;
  const distance =
    controlPoints["kPC_" + controlPointIndex].modalidadesPuntoDeControl[
      "modalidad_" + distanceIndex
    ].modalidad;

  var maxModId = Object.values(laps).reduce((prev, current) =>
    prev.lap > current.lap ? prev : current
  );
  const lap = maxModId.lap + 1;

  const defaultObject = {
    descripcionPunto: "Lap Nro:" + lap,
    kilometro: distance / 2,
    lap: lap,
    maxTime: "00:07:00",
    minTime: "00:00:01",
    tipoControl: 2,
    unidadPaso: "min/k",
  };

  const route =
    "puntosDeControl/kPC_" +
    controlPointIndex +
    "/modalidadesPuntoDeControl/modalidad_" +
    distanceIndex +
    "/laps/lap_" +
    lap;
  return (dispatch) => {
    console.log("Punto de control creado");
    if (eventoIndex) {
      // referencia a la base de datos

      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(defaultObject)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(showSnackbar(true, "Vuelta Creada correctamente ", 5000));

          dispatch(
            createEventLog(eventoIndex, { action: "createSubEvent" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function deleteSubEvent(
  eventoIndex,
  userID,
  eventSubEventsRegistry,
  subEventIndex
) {
  const route = "modalidadesInscripcion/" + subEventIndex + "/";

  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (eventoIndex) {
      // referencia a la base de datos

      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(null)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            createEventLog(eventoIndex, { action: "deleteSubEvent" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function createCategory(
  eventoIndex,
  data,
  userID,
  eventSubEventsRegistry,
  subEventIndex,
  gender
) {
  const categories =
    eventSubEventsRegistry[subEventIndex].ramaCat[gender].categorias;
  var maxModId = Object.values(categories).reduce((prev, current) =>
    prev.codigo > current.codigo ? prev : current
  );
  const codigo = maxModId.codigo + 1;

  const defaultObject = {
    absolutos: false,
    cantidadGanadores: 3,
    codigo: codigo,
    edadDesde: data.edadDesde || 18,
    edadHasta: data.edadHasta || 29,
    inscritos: 0,
    serie: 1,
    minimoParticipantes: 10,
    nombreCategoria: data.nombreCategoria || "Libre",
    precioKey: "precio_1",
  };
  console.log(defaultObject);

  const route =
    "modalidadesInscripcion/" +
    subEventIndex +
    "/ramaCat/" +
    gender +
    "/categorias/categoria_" +
    codigo +
    "/";

  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (eventoIndex) {
      // referencia a la base de datos

      console.log(data);
      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(defaultObject)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            createEventLog(eventoIndex, { action: "createCategory" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function createCategoryTiming(
  eventoIndex,
  data,
  userID,
  eventSubEventsTiming,
  subEventIndex,
  gender
) {
  const categories =
    eventSubEventsTiming[subEventIndex].ramaCat[gender].categorias;
  var maxModId = Object.values(categories).reduce((prev, current) =>
    prev.codigo > current.codigo ? prev : current
  );
  const codigo = maxModId.codigo + 1;

  const defaultObject = {
    absolutos: false,
    cantidadGanadores: data.cantidadGanadores || 3,
    codigo: codigo,
    edadDesde: data.edadDesde || 18,
    edadHasta: data.edadHasta || 29,
    inscritos: 0,
    serie: 1,
    minimoParticipantes: 10,
    nombreCategoria: data.nombreCategoria || "Libre",
    precioKey: "precio_1",
  };
  console.log(defaultObject);

  const route =
    "modalidades/" +
    subEventIndex +
    "/ramaCat/" +
    gender +
    "/categorias/categoria_" +
    codigo +
    "/";

  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (eventoIndex) {
      // referencia a la base de datos

      console.log(data);
      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(defaultObject)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            createEventLog(eventoIndex, { action: "createCategory" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function createCategoryTimingAsolutes(
  eventoIndex,
  data,
  userID,
  eventSubEventsTiming,
  subEventIndex,
  gender,
  deleteCategory
) {
  const codigo = 0;

  let defaultObject = {
    absolutos: true,
    cantidadGanadores: data.cantidadGanadores || 3,
    codigo: codigo,
    edadDesde: 0,
    edadHasta: 99,
    inscritos: 0,
    serie: 1,
    minimoParticipantes: 10,
    nombreCategoria: data.nombreCategoria || "Absolutos",
    precioKey: "precio_1",
  };
  if (deleteCategory) {
    defaultObject = null;
  }
  console.log(defaultObject);

  const route =
    "modalidades/" +
    subEventIndex +
    "/ramaCat/" +
    gender +
    "/categorias/categoria_" +
    codigo +
    "/";

  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (eventoIndex) {
      // referencia a la base de datos

      console.log(data);
      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(defaultObject)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            createEventLog(eventoIndex, { action: "createCategory" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}

//#################################################################
export function createStartTiming(
  eventoIndex,
  data,
  userID,
  eventSubEventsTiming,
  subEventIndex
) {
  const starts = eventSubEventsTiming[subEventIndex].hSalidas;
  var maxModId = Object.values(starts).reduce((prev, current) =>
    prev.serie > current.serie ? prev : current
  );
  const codigo = maxModId.serie + 1;

  const defaultObject = {
    descripcionSerie: "Salida " + codigo,
    hS: "0000-00-00 00:00:00",
    serie: codigo,
  };
  console.log(defaultObject);

  const route =
    "modalidades/" + subEventIndex + "/hSalidas/serie_" + codigo + "/";

  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (eventoIndex) {
      // referencia a la base de datos

      console.log(data);
      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(defaultObject)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            createEventLog(eventoIndex, { action: "createStart" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function deleteCategory(
  eventoIndex,
  userID,
  eventSubEventsRegistry,
  subEventIndex,
  gender,
  categoryCode
) {
  const route =
    "modalidadesInscripcion/" +
    subEventIndex +
    "/ramaCat/" +
    gender +
    "/categorias/categoria_" +
    categoryCode +
    "/";

  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (eventoIndex) {
      // referencia a la base de datos

      return fb.db
        .ref(`${fb.EventosCompletos}/${eventoIndex}/${route}/`)
        .set(null)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(
            createEventLog(eventoIndex, { action: "deleteSubEvent" }, route)
          );
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################

export function detalleEvento(eventoIndex) {
  console.log("detalle evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.DetalleEvento}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.EVENT_DETAIL,
        eventDetail: snapshot.val(),
      });
    });
  };
}
//#################################################################
export function configuracionEvento(eventoIndex) {
  console.log("configuracion evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Configuracion}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.EVENT_CONFIGURATION,
        eventConfiguration: snapshot.val(),
      });
    });
  };
}
//#################################################################
export function resultadosEvento(eventoIndex) {
  console.log("Resultados evento");
  var ref = fb.db.ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.Resultados}`);
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      var data = snapshot.val();
      var ResultadosEvento = [];
      for (var indice in data) {
        ResultadosEvento.push(data[indice]);
      }
      dispatch({
        type: types.EVENT_RESULTS,
        eventResults: ResultadosEvento,
      });
    });
  };
}
//#################################################################
export function resultadosEventoNew(eventoIndex) {
  console.log("resultadosEventoNew");
  var ref = fb.db.ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.Resultados}`);
  var ref1 = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Modalidades}`
  );
  return (dispatch) => {
    let eventSubEventsNew = {};
    console.log("absolutos", eventSubEventsNew);

    ref1
      .once("value")
      .then((modalidades) => {
        eventSubEventsNew = modalidades.val();
        console.log("absolutos", eventSubEventsNew);
        return ref.once("value");
      })
      .then((snapshot) => {
        console.log("absolutos", snapshot.val());
        if (snapshot.val()) {
          let ResultadosEvento = Object.values(snapshot.val());

          dispatch({
            type: types.EVENT_RESULTS,
            eventResults: calculatePositions(
              ResultadosEvento,
              eventSubEventsNew
            ),
          });
        } else {
          dispatch({
            type: types.EVENT_RESULTS,
            eventResults: [],
          });
        }
      });

    ref.on("child_changed", (snapshot) => {
      console.log("results", snapshot);
      const dato = snapshot.val();
      const eventResults = store.getState().events.eventResults;

      const index = eventResults.findIndex((obj) => {
        return obj.num === dato.num;
      });

      eventResults[index] = dato;

      console.log("child chaged");
      dispatch({
        type: types.EVENT_RESULTS,
        eventResults: calculatePositions(eventResults, eventSubEventsNew),
      });
    });
    ref.limitToLast(1).on("child_added", (snapshot) => {
      console.log("results", snapshot.val());
      const dato = snapshot.val();
      let eventResults = store.getState().events.eventResults;
      console.log("results1", eventResults);

      if (eventResults) {
        eventResults.push(dato);

        console.log("child added");
        dispatch({
          type: types.EVENT_RESULTS,
          eventResults: calculatePositions(eventResults, eventSubEventsNew),
        });
      }
    });
    ref.on("child_removed", (snapshot) => {
      console.log("results", snapshot.val());
      const dato = snapshot.val();
      const eventResults = store.getState().events.eventResults;

      const index = eventResults.findIndex((obj) => {
        return obj.num === dato.num;
      });

      delete eventResults[index];

      console.log("child removed");
      dispatch({
        type: types.EVENT_RESULTS,
        eventResults: calculatePositions(ResultadosEvento, eventSubEventsNew),
      });
    });
  };
}
//#################################################################
export function getAllRegistries(eventoIndex) {
  console.log("regitros recibidos del evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.RegistrosJuez}`
  );
  return (dispatch) => {
    let eventSubEventsNew = {};
    console.log("absolutos", eventSubEventsNew);

    ref.once("value").then((snapshot) => {
      console.log("registriesEvent", snapshot.val());
      if (snapshot.val()) {
        let RegistriesEvent = Object.values(snapshot.val());

        dispatch({
          type: types.EVENT_RESULTS_REGISTRY,
          eventResultsRegistries: RegistriesEvent,
        });
      } else {
        dispatch({
          type: types.EVENT_RESULTS_REGISTRY,
          eventResultsRegistries: [],
        });
      }
    });

    ref.on("child_changed", (snapshot) => {
      console.log("results", snapshot.val());
      const dato = snapshot.val();
      const eventResultsRegistries = store.getState().events
        .eventResultsRegistries;

      let RegistriesEvent = [];
      for (var i in eventResultsRegistries) {
        if (eventResultsRegistries[i].num === dato.num) {
          RegistriesEvent.push(dato);
        } else {
          RegistriesEvent.push(eventResultsRegistries[i]);
        }
      }
      console.log("child chaged");
      dispatch({
        type: types.EVENT_RESULTS_REGISTRY,
        eventResultsRegistries: RegistriesEvent,
      });
    });
    ref.limitToLast(1).on("child_added", (snapshot) => {
      console.log("results", snapshot.val());
      const dato = snapshot.val();
      let eventResultsRegistries = store.getState().events
        .eventResultsRegistries;
      console.log("results1", eventResultsRegistries);

      if (eventResultsRegistries) {
        let RegistriesEvent = eventResultsRegistries;

        console.log("results1", RegistriesEvent);
        RegistriesEvent.push(dato);

        console.log("child added");
        dispatch({
          type: types.EVENT_RESULTS_REGISTRY,
          eventResultsRegistries: RegistriesEvent,
        });
      }
    });
    ref.on("child_removed", (snapshot) => {
      console.log("results", snapshot.val());
      const dato = snapshot.val();
      const eventResultsRegistries = store.getState().events
        .eventResultsRegistries;

      let RegistriesEvent = [];
      for (var i in eventResultsRegistries) {
        if (eventResultsRegistries[i].num !== dato.num) {
          RegistriesEvent.push(eventResultsRegistries[i]);
        }
      }

      console.log("child removed");
      dispatch({
        type: types.EVENT_RESULTS_REGISTRY,
        eventResultsRegistries: RegistriesEvent,
      });
    });
  };
}
//#################################################################
export function athleteJuezRegistries(eventoIndex, number) {
  console.log("registry Juez");

  console.log("registry Juez", eventoIndex);
  console.log("registry Juez", number);

  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.RegistrosJuez}/num_${number}`
  );
  return (dispatch) => {
    dispatch({
      type: types.ATHLETE_JUEZ_REGISTRY,
      registryJuez: null,
    });

    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      var data = snapshot.val();
      var registryJuez = [];
      for (var indice in data) {
        registryJuez.push({ ...data[indice], index: indice });
      }
      dispatch({
        type: types.ATHLETE_JUEZ_REGISTRY,
        registryJuez: registryJuez,
      });
    });
  };
}
//#################################################################
export function getLocalRegistries(eventid, kPuntoControl) {
  return (dispatch) => {
    console.log(
      "Obtener datos del local storage para ese evento y punto de control"
    );
    dispatch({
      type: types.LOCAL_REGISTRIES,
      localRegistries: {},
    });

    const registries = JSON.parse(
      localStorage.getItem("DASHPORT/" + eventid + "/" + kPuntoControl)
    );
    console.log("registries", registries);
    if (registries) {
      dispatch({
        type: types.LOCAL_REGISTRIES,
        localRegistries: registries,
      });
    }
  };
}
//#################################################################
export function createJuezRegistryNew(
  registry,
  registries,
  eventid,
  kPuntoControl,
  resend
) {
  return (dispatch) => {
    console.log("Guardar datos de evento");
    let updates = {};

    // referencia a la base de datos

    console.log("numero", registry, registries);

    var key;

    if (resend) {
      key = registry.id;
      delete registry.id;
      delete registry.sync;

      updates[
        `${fb.EventosCompletos}/${"evento_" + registry.iDEvento}/${
          fb.RegistrosJuez
        }/${"num_" + registry.numero}/${key}/`
      ] = registry;
    } else {
      key = fb.db
        .ref()
        .child(
          `${fb.EventosCompletos}/${"evento_" + registry.iDEvento}/${
            fb.RegistrosJuez
          }/${"num_" + registry.numero}/`
        )
        .push().key;

      updates[
        `${fb.EventosCompletos}/${"evento_" + registry.iDEvento}/${
          fb.RegistrosJuez
        }/${"num_" + registry.numero}/${key}/`
      ] = registry;

      registries[key] = { ...registry, sync: false, id: key };

      localStorage.setItem(
        "DASHPORT/" + eventid + "/" + kPuntoControl,
        JSON.stringify({ ...registries })
      );
      dispatch({
        type: types.LOCAL_REGISTRIES,
        localRegistries: registries,
      });
    }

    return fb.db
      .ref()
      .update(updates)
      .then((data) => {
        registries[key] = { ...registry, sync: true, id: key };

        console.log("numero", registries);

        localStorage.setItem(
          "DASHPORT/" + eventid + "/" + kPuntoControl,
          JSON.stringify({ ...registries })
        );

        console.log("Datos Guardados", data);
        dispatch({
          type: types.LOCAL_REGISTRIES,
          localRegistries: registries,
        });
      })
      .catch(function(error) {
        console.log("Error" + error);
      });
  };
}
//#################################################################
export function deleteRegistry(registry, registries, eventid, kPuntoControl) {
  return (dispatch) => {
    console.log("Guardar datos de evento");
    let updates = {};

    // referencia a la base de datos

    const key = registry.id;
    delete registry.id;
    delete registry.sync;

    console.log("numero", registry, registries);
    updates[
      `${fb.EventosCompletos}/${"evento_" + registry.iDEvento}/${
        fb.RegistrosJuez
      }/${"num_" + registry.numero}/${key}/`
    ] = registry;

    registries[key] = { ...registry, sync: false, id: key };

    dispatch({
      type: types.LOCAL_REGISTRIES,
      localRegistries: registries,
    });
    localStorage.setItem(
      "DASHPORT/" + eventid + "/" + kPuntoControl,
      JSON.stringify({ ...registries })
    );

    return fb.db
      .ref()
      .update(updates)
      .then((data) => {
        registries[key] = { ...registry, sync: true, id: key };

        console.log("numero", registries);

        localStorage.setItem(
          "DASHPORT/" + eventid + "/" + kPuntoControl,
          JSON.stringify({ ...registries })
        );

        console.log("Datos Guardados", data);
        dispatch({
          type: types.LOCAL_REGISTRIES,
          localRegistries: registries,
        });
      })
      .catch(function(error) {
        console.log("Error" + error);
      });
  };
}
//#################################################################
export function createJuezRegistry(registry, update, index) {
  return (dispatch) => {
    console.log("Guardar datos de evento");
    if (!update) {
      // referencia a la base de datos

      return fb.db
        .ref(
          `${fb.EventosCompletos}/${"evento_" + registry.iDEvento}/${
            fb.RegistrosJuez
          }/${"num_" + registry.numero}/`
        )
        .push()
        .set(registry)
        .then((data) => {
          console.log("Datos Guardados", data);
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    } else {
      var updates = {};
      updates[
        `${fb.EventosCompletos}/${"evento_" + registry.iDEvento}/${
          fb.RegistrosJuez
        }/${"num_" + registry.numero}/${index}`
      ] = registry;
      console.log("updates", updates);
      return fb.db
        .ref()
        .update(updates)
        .then(function() {
          console.log("Datos Guardados");
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    }
  };
}
//#################################################################
export function updateAthleteResult(eventIndex, number, changeObject) {
  return (dispatch) => {
    console.log("Guardar datos de evento");
    console.log("updateAthleteResult", changeObject);

    var updates = {};
    for (var i in changeObject) {
      updates[
        `${fb.EventosCompletos}/${eventIndex}/${fb.Resultados}/${"num_" +
          number}/${i}`
      ] = changeObject[i];
    }

    console.log(updates);
    return fb.db
      .ref()
      .update(updates)
      .then(function() {
        console.log("Datos Guardados");
      })
      .catch(function(error) {
        console.log("Error" + error);
      });
  };
}
//#################################################################
export function getPuntosControl(eventoIndex) {
  console.log("detalle evento" + eventoIndex);
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.PuntosDeControl}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.EVENT_POINTS_CONTROLS,
        puntosControl: snapshot,
      });
    });
  };
}
//#################################################################
export function eventPhotoConfiguration(eventoIndex) {
  console.log("Configuracion de fotos" + eventoIndex);
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.fotosEventoRuta}${fb.configuracionFotosRuta}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_PHOTOS_CONFIGURATION,
        eventPhotoConfiguration: snapshot.val(),
      });
    });
  };
}
//#################################################################
export function eventConvocatory(eventoIndex) {
  console.log("Convatoria Evento evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Convocatoria}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_CONVOCATORY,
        eventConvocatory: snapshot.val(),
      });
    });
  };
}
//#################################################################
export function eventWeb(eventoIndex) {
  console.log("Web Evento evento");
  var ref = fb.db.ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.web}`);
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_WEB,
        eventWeb: snapshot.val(),
      });
    });
  };
}
//#################################################################
export function eventSubEvents(eventoIndex) {
  console.log("Convatoria Evento evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Modalidades}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_SUBEVENTS,
        eventSubEvents: snapshot.val(),
        eventSubEventsSnap: snapshot,
      });
    });
  };
}
//#################################################################
export function eventSubEventsRegistry(eventoIndex) {
  console.log("Convatoria Evento evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.ModalidadesInscripciones}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_SUBEVENTS_REGISTRY,
        eventSubEventsRegistry: snapshot.val(),
        eventSubEventsRegistrySnap: snapshot,
      });
    });
  };
}
//#################################################################
export function juecesEvento(eventoIndex) {
  console.log("jueces Evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.JuecesEvento}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_USERS,
        eventUsers: snapshot.val(),
      });
    });
  };
}
//#################################################################
export function getDepositsList(eventoIndex) {
  console.log("jueces Evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Deposits}/${fb.DepositsList}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      const data = snapshot.val();
      for (var i in data) {
        data[i] = { ...data[i], index: i };
      }
      dispatch({
        type: types.EVENT_DEPOSITS_LIST,
        eventDepositsList: data,
      });
    });
  };
}
//#################################################################
export function getDepositsRequested(eventoIndex) {
  console.log("jueces Evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Deposits}/${fb.DepositsRequested}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      const data = snapshot.val();
      for (var i in data) {
        data[i] = { ...data[i], index: i };
      }
      dispatch({
        type: types.EVENT_DEPOSITS_REQUESTED,
        eventDepositsRequested: data,
      });
    });
  };
}
//#################################################################
export function setEventAccount(eventIndex, report) {
  return (dispatch) => {
    console.log("Guardar reporte del evento");
    var updates = {};
    updates[`${fb.eventsAccount}/${eventIndex}`] = report;

    return fb.db
      .ref()
      .update(updates)
      .then(function() {
        console.log("Datos Guardados");
      })
      .catch(function(error) {
        console.log("Error" + error);
      });
  };
}
//#################################################################
export function getEventsAcccount() {
  console.log("jueces Evento");
  var ref = fb.db.ref(`${fb.eventsAccount}`);
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      const data = snapshot.val();
      dispatch({
        type: types.EVENTS_ACCOUNT,
        eventsAccount: data,
      });
    });
  };
}
//#################################################################
export function getDepositsUserList(eventoIndex) {
  console.log("jueces Evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Deposits}/${fb.UsersList}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_DEPOSITS_USERS,
        eventDepositsUsers: snapshot.val(),
      });
    });
  };
} //#################################################################
export function customStrings(eventoIndex) {
  console.log("Convatoria Evento evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.customStrings}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.CUSTOM_STRINGS,
        customStrings: snapshot.val(),
      });
    });
  };
}
//#################################################################
//#################################################################
export function getEventDataToCopy(eventoIndex, currentEvent) {
  console.log("getEventDataToCopy", eventoIndex, currentEvent);
  const refModalidadesInscripciones = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.ModalidadesInscripciones}`
  );
  const refInscripcionesConfiguracion = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.InscripcionesConfiguracion}`
  );
  const refWeb = fb.db.ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.web}`);
  let data = {};
  return (dispatch) => {
    return refModalidadesInscripciones
      .once("value")
      .then((snapshot) => {
        let modalidadesInscripcion = snapshot.val();
        for (var m in modalidadesInscripcion) {
          modalidadesInscripcion[m].configModalidad.folios.inscritos = 0;
          for (var g in modalidadesInscripcion[m].ramaCat) {
            for (var c in modalidadesInscripcion[m].ramaCat[g].categorias) {
              delete modalidadesInscripcion[m].ramaCat[g].categorias[c]
                .modGenCatCrono;
            }
          }
        }
        data.modalidadesInscripcion = modalidadesInscripcion;

        // dispatch(
        //   updateEventObject(
        //     currentEvent,
        //     "modalidadesInscripcion/",
        //     modalidadesInscripcion
        //   )
        // );
        return refInscripcionesConfiguracion.once("value");
      })
      .then((snapshot) => {
        data.inscripcionesConfiguracion = snapshot.val();
        // dispatch(
        //   updateEventObject(
        //     currentEvent,
        //     "inscripcionesConfiguracion/",
        //     snapshot.val()
        //   )
        // );
        return refWeb.once("value");
      })
      .then((snapshot) => {
        let web = snapshot.val();
        for (var i in web.views) {
          if (web.views[i].component === "eventRegistry") {
            web.views[i].componentConfig.eventIdDashport = currentEvent;
          }
        }
        data.web = web;

        // dispatch(updateEventObject(currentEvent, "web/", web));
        dispatch({
          type: types.EVENT_DATA_TO_COPY,
          eventDataToCopy: data,
        });
      });
  };
}
// export function eventCreateSubEventsRegistry(eventoIndex) {
//   console.log("Convatoria Evento evento");
//   var ref = fb.db.ref(
//     `${fb.EventosCompletos}/${eventoIndex}/${fb.Modalidades}`
//   );
//   return (dispatch) => {
//     ref.once("value", (snapshot) => {
//       console.log(snapshot.val())
//       if (snapshot.exists()) {
//         console.log(snapshot.val())

//         return fb.db
//       .ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.ModalidadesInscripciones}/`)
//       .set(snapshot.val())
//       .then(function() {
//         console.log("Datos Guardados");
//       })
//       .catch(function(error) {
//         console.log("Error" + error);
//       });
//        } else {
//         console.log("no existe")
//       }
//     });
//   };
// }
export function createEventLog(eventoIndex, data, route) {
  console.log("Guardar datos");

  if (eventoIndex) {
    // referencia a la base de datos
    const date = new Date().getTime();
    const userID = store.getState().atlete.userID;

    const finalRoute = `${fb.EventosCompletos}/${eventoIndex}/logs/${date}/`;

    return (dispatch) => {
      return fb.db
        .ref(finalRoute)
        .set({
          data: data,
          route: route || "NA",
          date: date,
          userIdCreate: userID || "NA",
        })
        .then(function() {
          //console.log("Datos Guardados");
        })
        .catch(function(error) {
          //console.log("Error" + error);
        });
    };
  }
}
//#################################################################
export function getCustomViews(eventoIndex) {
  console.log("Get cutom views");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.CustomViews}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.CUSTOM_VIEWS,
        customViews: snapshot.val(),
      });
    });
  };
}
//#################################################################
export function getTrakingLinks(eventoIndex) {
  console.log("tracking links" + eventoIndex);
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.trackingLinks}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.TRACKING_LINKS,
        trackingLinks: { [eventoIndex]: snapshot.val() },
      });
    });
  };
}

//#################################################################

export function getCheckChip(eventoIndex) {
  console.log("check chip");
  var ref = fb.db.ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.checkChip}/`);
  return (dispatch) => {
    ref.limitToLast(30).on("child_added", (snapshot) => {
      console.log(snapshot.val());
      console.log("child added");
      dispatch({
        type: types.EVENT_CHECKCHIP,
        checkChip: snapshot.val(),
        checkChipKey: snapshot.key,
      });
    });
  };
}
