import React from "react";
import { connect, dispatch } from "react-redux";
import { initEvents } from "../../data-store/actions/events-actions";
import QRCodeCanvas from "qrcode.react";

import Circle from "./Circle";
import FacebookProvider, { SendToMessenger } from "react-facebook";
import imagePromise from "image-promise";
//import ConnectToLocalServer from './ConnectToLocalServer';

import CameraDashport from "./AuthorizedUsers/Timing/CameraDashport";
import CameraFeed from "./AuthorizedUsers/Timing/CameraFeed";

import PhotoEditorContainer from "./PhotoEditor/PhotoEditorContainer";
// area de codigo para conectarse al lector

const resultado = {
  ab: 0,
  cat: "Elite",
  cg: 5,
  codCat: 1,
  codMod: 1,
  fhm: "2020-02-21 20:55:44.506",
  gen: "V",
  mod: 5,
  mp: 0,
  nombre: "JARED VELAZCO FLORES",
  num: 2,
  pCat: 1,
  pG: 1,
  pR: 1,
  registroSalida: false,
  serie: 1,
  tc: "00:14:46",
  to: "00:14:46",
};

const HomeView = ({ events, onTodoClick, eventos, context }) => {
  //<iframe src="https://www.facebook.com/plugins/page.php?href=https:%2F%2Fwww.facebook.com%2Fcoderexample&tabs=timeline%2Cmessages&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=496223437090509" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>

  //<CameraDashport />
  //<AtletePhotos/>

  //<ConnectToLocalServer/>

  const base64 = (string) => {
    // Encode the String
    var encodedString = btoa(string);
    console.log(encodedString); // Outputs: "SGVsbG8gV29ybGQh"

    // Decode the String
    var decodedString = atob(encodedString);
    console.log(decodedString); // Outputs: "Hello World!"

    return encodedString;
  };
  return (
    <div>
      <br />
      <br />
      <PhotoEditorContainer
        buttomLabel={"Abrir Editor"}
        title={"Editor y descarga de fotos"}
        resultado={resultado}
        urlPhoto={
          "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/fotosEventos%2Fevento_315%2FfullSize%2FDSC_0115_37529.JPG?alt=media&token=c6f1d613-5ac5-4600-9790-2a57a3543d02"
        }
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h5>Prueba CODI OJOOOO</h5>
      <p>
        Este codigo se genero con un ejm de un qr de cobro con mi app de
        bancomer
      </p>
      {/* <QRCode value='{"ot":"0001","dOp":[{"alias":"prueba"},{"amount":"10.0"},{"country":"MX"},{"bank":"00012"},{"refa":"EduardoMadrid Alvarez De Lugo"},{"currency":"MXN"},{"type":"CL"},{"refn":""},{"cl":"012910015632716877"}]}' /> */}
      <h5>Prueba Chat Hubspot</h5>
      <h5>Prueba de links de amazon afiliados</h5>
      <a
        target="_blank"
        href="https://www.amazon.com.mx/b?_encoding=UTF8&tag=dashport-20&linkCode=ur2&linkId=510574be6e75a213c9b2b63e9b6fc989&camp=1789&creative=9325&node=9784025011"
      >
        atletismo
      </a>
      <img
        src="//ir-mx.amazon-adsystem.com/e/ir?t=dashport-20&l=ur2&o=34"
        width="1"
        height="1"
        border="0"
        alt=""
        style={{ border: "none", margin: "0px" }}
      />
      <a
        target="_blank"
        href="https://www.amazon.com.mx/Timotech-Cangurera-Cintur%C3%B3n-Running-Incluidas/dp/B07GV2RLMC/ref=sr_1_5?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&amp;keywords=running&amp;qid=1567525145&amp;s=gateway&amp;sr=8-5&_encoding=UTF8&tag=dashport-20&linkCode=ur2&linkId=d9f436deca3e38320d057410ca254bd1&camp=1789&creative=9325"
      >
        Prueba coala running
      </a>
      <img
        src="//ir-mx.amazon-adsystem.com/e/ir?t=dashport-20&l=ur2&o=34"
        width="1"
        height="1"
        border="0"
        alt=""
      />
      <h5>Prueba de link para chat de facebook</h5>
      <h5 onClick={(e) => onTodoClick()}>Home</h5>
      <a href="https://m.me/dashportrun">Envíanos un mensaje de Facebook</a>
      <FacebookProvider appId="482146735528019">
        <SendToMessenger
          messengerAppId="482146735528019"
          pageId="482146735528019"
        />
      </FacebookProvider>
      <h5>Prueba de link para chat bot</h5>
      <iframe
        width={600}
        height={600}
        src={"https://bot.dialogflow.com/bc110003-c17f-41bb-87e6-8efe99d7a6fe"}
      ></iframe>
      <h5>Prueba qrcode</h5>

      <QRCodeCanvas value="https://reactjs.org/" />
      <h5>Graficas</h5>
      <Circle />

      <QRCodeCanvas
        value={"https://dashport.run/evento_967"}
        size={400}
        bgColor={"#ffffff"}
        fgColor={"#ea4410"}
        includeMargin={false}
        imageSettings={{
          src:
            "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/fotosVarias%2F1701110428825%2FDASHPORT_isotipo%20paraicono.png?alt=media&token=65b87ee6-279b-4cdf-a45a-17f5cbe786a8",
          excavate: true,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTodoClick: () => {
      dispatch(initEvents());
    },
  };
};

const Home = connect(mapStateToProps, mapDispatchToProps)(HomeView);

export default Home;
