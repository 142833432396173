import React from "react";
import { connect } from "react-redux";
import PhotoEditorContainer from "../../PhotoEditor/PhotoEditorContainer";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
//https://www.npmjs.com/package/pure-react-carousel#examples

const SlideStyle = {
  margin: "5px",
  boxShadow: "1px 2px 4px rgba(0,0,0,.5)",
};

class AtletePhotosView extends React.Component {
  //{resultado, atletePhotos}
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      visibleSlides: window.innerWidth > 992 ? 5 : 3,
    };
  }

  arrayPhotos = (arrayPhotosProps) => {
    let photosArray = {};
    photosArray.data = [];
    photosArray.medio = 0;
    photosArray.cantidad = 0;

    console.log(JSON.stringify(arrayPhotosProps));

    var x = 0;
    for (var i in arrayPhotosProps) {
      x = x + 1;
      if (arrayPhotosProps[i].horaAjustada < this.props.resultado.fhm) {
        photosArray.medio = x;
      }
      console.log(arrayPhotosProps[i].urlThumb);

      photosArray.data.push(arrayPhotosProps[i]);
    }
    photosArray.cantidad = x;

    return photosArray;
  };

  render() {
    console.log("render");
    let carousel;
    let photosArray = {};
    photosArray.data = [];
    photosArray.medio = 0;
    photosArray.cantidad = 1000;

    if (this.props.atletePhotos != null) {
      photosArray = this.arrayPhotos(this.props.atletePhotos);
      carousel = (
        <Slider>
          {photosArray.data.map((photo, index) => (
            <Slide index={index} key={index} style={SlideStyle}>
              <PhotoEditorContainer
                buttomLabel={"Abrir Editor"}
                title={"Editor"}
                resultado={this.props.resultado}
                urlPhoto={photo.url}
              />

              <img src={photo.urlThumb} width="100%" />
            </Slide>
          ))}
        </Slider>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="col s10 m10 l10">
            <h5>Fotos del atleta</h5>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m12 l12">
            <div className="caruselAlto">
              <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={100}
                totalSlides={photosArray.cantidad}
                currentSlide={photosArray.medio}
                step={1}
                visibleSlides={this.state.visibleSlides}
              >
                {carousel}
              </CarouselProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    atletePhotos: state.atlete.atletePhotos,
  };
};

const AtletePhotos = connect(
  mapStateToProps,
  mapDispatchToProps
)(AtletePhotosView);

export default AtletePhotos;
