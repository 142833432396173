import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import createEventConfig from "./EventCreate/createEventconfig.json";
import {
  updateEventAttribute,
  deleteCategory,
} from "../../../../data-store/actions/events-actions";
import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventSpecificCategory(props) {
  const { subEventIndex, subEvent, gender, category } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const [modalidadIndex, setModalidadIndex] = useState("modalidad_1");

  const createEventObject = createEventConfig.categoria;

  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "modalidadesInscripcion/" +
          subEventIndex +
          "/ramaCat/" +
          gender +
          "/categorias/categoria_" +
          category.codigo +
          "/",
        value
      )
    );
  }
  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    console.log("value1", fieldName);
    console.log("value1", value);
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }
    console.log(value);

    return value;
  }

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
        <Typography gutterBottom variant={"h3"} component="h2">
          {category.nombreCategoria}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} style={{ width: "100%" }}>
        {Object.values(createEventObject).map((field, index) => {
          console.log("value1", subEventIndex, gender, category);
          const defaultValue = findValueByRoute(
            eventSubEventsRegistry[subEventIndex].ramaCat[gender].categorias[
              "categoria_" + category.codigo
            ],
            field.fieldName
          );
          let camposAdicionalesKeyList = [{ value: null, label: "Ninguno" }];

          if (
            eventSubEventsRegistry[subEventIndex].configModalidad
              .camposAdicionalesModalidad !== undefined
          ) {
            const camposAdicionalesModalidad =
              eventSubEventsRegistry[subEventIndex].configModalidad
                .camposAdicionalesModalidad;
            for (var i in camposAdicionalesModalidad) {
              console.log("aaaaa", i);
              camposAdicionalesKeyList.push({ value: i, label: i });
            }
          }

          console.log("aaaaa", camposAdicionalesKeyList);
          return (
            <EditableField
              key={index}
              fieldObject={{
                ...field,
                optionsArray: camposAdicionalesKeyList,
                defaultValue: defaultValue,
              }}
              onSubmit={handleChange}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
