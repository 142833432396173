import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { atletePhotosFunc } from "../../../../data-store/actions/atlete-actions";
import { eventPhotoConfiguration } from "../../../../data-store/actions/events-actions";

import AtleteControls from "./AtleteControls";
import AtleteCharts from "./AtleteCharts";
import AtletePhotos from "./AtletePhotos";
import { puntoFotograficoPrevRuta } from "../../../../firebase/firebasejs";

export default function AtleteReportsContainer({ resultado }) {
  //({eventDetail, resultado, atletePhotos, eventConfiguration})
  const atletePhotos = useSelector((state) => state.atlete.atletePhotos);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const puntosControl = useSelector(
    (state) => state.events.puntosControl
  ).val();
  const dispatch = useDispatch();

  useEffect(() => {
    var puntoFotografico = 1;
    var eventoIndex = `evento_${eventDetail.iDEvento}`;
    var fechaHoraMeta = resultado.fhm;

    dispatch(atletePhotosFunc(eventoIndex, fechaHoraMeta, puntoFotografico));
    dispatch(eventPhotoConfiguration(eventoIndex));
  }, [resultado]);

  return (
    <div className="row">
      <div className="col s12">
        <hr />
        <AtleteControls resultado={resultado} puntosControl={puntosControl} />
        <hr />
        <AtleteCharts resultado={resultado} />
        <hr />
        <AtletePhotos resultado={resultado} atletePhotos={atletePhotos} />
        <hr />
      </div>
    </div>
  );
}
