import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";
import firebase from "firebase";
import { fechahora } from "../../services/utils";
import { showSnackbar } from "./organizers-actions";

export function eventParticipants(eventoIndex) {
  console.log("Participantes o inscritos Evento evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.inscritos}${fb.listadoInscritos}`
  );
  return (dispatch) => {
    ref.once("value", (snapshot) => {
      console.log(snapshot.val());
      console.log("events participan value");
      dispatch({
        type: types.EVENT_PARTICIPANTS,
        eventParticipants: snapshot.val(),
        eventParticipantsRequested: true,
        eventParticipantsEventIndex: eventoIndex,
      });
    });

    ref.on("child_changed", (snapshot) => {
      console.log(snapshot.val());
      console.log("child chaged");
      dispatch({
        type: types.EVENT_PARTICIPANTS_CHANGED,
        eventParticipantsChanged: snapshot.val(),
        eventParticipantsChangedKey: snapshot.key,
      });
    });
    ref.limitToLast(1).on("child_added", (snapshot) => {
      console.log(snapshot.val());
      console.log("child added");
      dispatch({
        type: types.EVENT_PARTICIPANTS_CHANGED,
        eventParticipantsChanged: snapshot.val(),
        eventParticipantsChangedKey: snapshot.key,
      });
    });
    ref.on("child_removed", (snapshot) => {
      console.log(snapshot.val());
      console.log("child removed");
      dispatch({
        type: types.EVENT_PARTICIPANTS_REMOVED,
        eventParticipantsChanged: snapshot.val(),
        eventParticipantsChangedKey: snapshot.key,
      });
    });
  };
}
export function registryConfiguration(eventoIndex) {
  console.log("Participantes o inscritos Evento evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.InscripcionesConfiguracion}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.REGISTRY_CONFIGURATION,
        registryConfiguration: snapshot.val(),
      });
    });
  };
}
export function registryAutorizedUsers(eventoIndex) {
  console.log("usuarios autorizados o inscritos Evento evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.OganizadoresEvento}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.REGISTRY_AUTORIZED_USERS,
        registryAutorizedUsers: snapshot.val(),
      });
    });
  };
}

export function resetRegistryParticipantData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_REGISTRY_PARTICIPANT_DATA,
      registryParticipantData: null,
      registryParticipantKey: null,
      registryError: null,
    });
  };
}

export function registryParticipantDataFunc(
  eventoIndex,
  atleteData,
  formaDeInscripcionparam,
  userID
) {
  //resetRegistryParticipantData();
  console.log("Guardar Datos de inscripcion");

  if (userID === undefined) {
    atleteData.usuarioRegistra = "No registrado";
  } else {
    atleteData.usuarioRegistra = userID;
  }
  atleteData.fechaRegistro = fechahora();
  atleteData.serverTime = firebase.database.ServerValue.TIMESTAMP;

  // 1 Inscripcion en linea
  // 2 Inscricppcion en punto de venta
  // 3 inscripcion por codigo

  if (formaDeInscripcionparam === 1) {
    atleteData.tipoRegistro = formaDeInscripcionparam;
    atleteData.statusPago = {
      status: "pendiente",
      formaInscripcion: "en linea",
      formaPago: "",
      fechaPago: "",
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
    };
  } else if (formaDeInscripcionparam === 2) {
    atleteData.tipoRegistro = formaDeInscripcionparam;
    atleteData.statusPago = {
      status: "ok",
      formaInscripcion: "punto de Venta",
      formaPago: "efectivo",
      fechaPago: fechahora(),
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
    };
  } else if (formaDeInscripcionparam === 3) {
    atleteData.tipoRegistro = formaDeInscripcionparam;
    atleteData.statusPago = {
      status: "ok",
      formaInscripcion: "Codigo de inscripción",
      formaPago: "efectivo",
      fechaPago: fechahora(),
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
    };
  } else if (formaDeInscripcionparam === 4) {
    atleteData.tipoRegistro = 2; // seteo a 2 para ver enviar correo como ya pagado
    atleteData.statusPago = {
      status: "ok",
      formaInscripcion: "en linea",
      formaPago: "efectivo",
      fechaPago: fechahora(),
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
    };
  }

  var route = `${fb.EventosCompletos}${eventoIndex}/${fb.inscritos}${fb.listadoInscritos}`;
  var inscritoKey = fb.db
    .ref()
    .child(route)
    .push().key;

  var updates = {};

  updates[`${route}${inscritoKey}/`] = atleteData;

  var ref = fb.db.ref(`${route}${inscritoKey}/`);
  return (dispatch) => {
    fb.db
      .ref()
      .update(updates)
      .then(function() {
        ref.on("value", (snapshot) => {
          console.log(snapshot.val());
          dispatch({
            type: types.REGISTRY_PARTICIPANT_DATA,
            registryParticipantData: snapshot.val(),
            registryParticipantKey: snapshot.key,
            registryError: null,
          });
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.REGISTRY_PARTICIPANT_DATA,
          registryParticipantData: null,
          registryParticipantKey: null,
          registryError: error,
        });
      });
  };
}
export function registryFormDataFunc(eventoIndex, formId, formData) {
  //resetRegistryParticipantData();
  console.log("Guardar Datos formulario");

  var route = `${fb.EventosCompletos}${eventoIndex}/${fb.formsData}/${formId}/`;
  var itemKey = fb.db
    .ref()
    .child(route)
    .push().key;

  var updates = {};

  formData.date = new Date().getTime();
  updates[`${route}${itemKey}/`] = formData;

  var ref = fb.db.ref(`${route}${itemKey}/`);
  return (dispatch) => {
    fb.db
      .ref()
      .update(updates)
      .then(function() {
        ref.on("value", (snapshot) => {
          console.log(snapshot.val());
          dispatch({
            type: types.REGISTRY_FORM_DATA,
            registryFormData: snapshot.val(),
            registryFormKey: snapshot.key,
            registryError: null,
          });
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.REGISTRY_FORM_DATA,
          registryFormData: null,
          registryFormKey: null,
          registryError: error,
        });
      });
  };
}

export function getallDataForConfirmationFunc(eventoIndex, inscritoKey) {
  // 	//resetRegistryParticipantData();
  console.log("registryParticipantData", eventoIndex, inscritoKey);

  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.InscripcionesConfiguracion}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.REGISTRY_CONFIGURATION,
        registryConfiguration: snapshot.val(),
      });
      return fb.db
        .ref(
          "/eventosCompletos/" +
            eventoIndex +
            "/inscritos/listadoInscritos/" +
            inscritoKey
        )
        .once("value")
        .then((snap) => {
          console.log("registryParticipantData", snap.val());
          dispatch({
            type: types.REGISTRY_PARTICIPANT_DATA,
            registryParticipantData: snap.val(),
            registryParticipantKey: snap.key,
            registryError: null,
          });
          return fb.db
            .ref(
              "/eventosCompletos/" +
                eventoIndex +
                "/inscripcionesConfiguracion/"
            )
            .once("value");
        })
        .then((snap) => {
          dispatch({
            type: types.REGISTRY_CONFIGURATION,
            registryConfiguration: snap.val(),
          });
        });
    });
  };

  //   return db.ref('/eventosCompletos/'+eventoIndex+"/inscritos/listadoInscritos/"+inscritoKey).once('value')
  // 	.then(snap => {
  // 	  jsonData.datos = snap.val();
  //   return db.ref('/eventosCompletos/'+eventoIndex+'/inscripcionesConfiguracion/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.inscripcionesConfiguracion = snap.val();
  // 	  JSONFormInscripcion = jsonData.inscripcionesConfiguracion;
  // 	  setBaseCurrency();

  //   return db.ref('/eventosCompletos/'+eventoIndex+'/detalleEvento/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.detalleEvento = snap.val();
  //   return db.ref('/eventosCompletos/'+eventoIndex+'/modalidades/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.modalidades = snap.val();

  // 	  return;
  // 	}).then(()=>{
  // 		if(descarga){
  // 		  console.log("descargar automatico");
  // 		  return setTimeout(()=> { return downloadDeslindePDFHTML(jsonData);},100)
  // 		  //return downloadDeslindePDF(jsonData);
  // 		}else {
  // 		  return;
  // 		}
  // 	});
}

export function generateVirtualEventsLink(
  eventParticipants,
  eventoIndex,
  eventSubEventsRegistry
) {
  return (dispatch) => {
    for (var i in eventParticipants) {
      console.log(eventSubEventsRegistry);
      let eventID = undefined;
      if (
        eventSubEventsRegistry[
          "modalidad_" + eventParticipants[i].modalidad
        ] !== undefined &&
        eventSubEventsRegistry["modalidad_" + eventParticipants[i].modalidad]
          .virtualEventId !== undefined
      ) {
        eventID =
          eventSubEventsRegistry["modalidad_" + eventParticipants[i].modalidad]
            .virtualEventId;
      }
      console.log(eventID);
      if (eventParticipants[i].numero !== undefined && eventID !== undefined) {
        setTimeout(
          createEventPass(eventID, eventoIndex, i, eventParticipants[i].numero),
          10
        );
      }
    }
  };
}
export function sendAllMails(
  eventParticipants,
  eventoIndex,
  eventSubEventsRegistry
) {
  return (dispatch) => {
    let x = 0;
    for (var i in eventParticipants) {
      console.log(eventSubEventsRegistry);
      if (
        eventParticipants[i].numero !== undefined &&
        !eventParticipants[i].borrado
      ) {
        console.log(x++);
        setTimeout(reenviarConfirmacion(eventoIndex, i), 100);
      }
    }
  };
}

function reenviarConfirmacion(eventoIndex, idRegistro) {
  console.log("reenviar");

  var updates = {};
  console.log(
    "eventosCompletos/" +
      eventoIndex +
      "/inscritos/listadoInscritos/" +
      idRegistro +
      "/mails/sendMail/"
  );
  updates[
    "eventosCompletos/" +
      eventoIndex +
      "/inscritos/listadoInscritos/" +
      idRegistro +
      "/mails/sendMail/"
  ] = true;
  return fb.db
    .ref()
    .update(updates)
    .then((snap) => {
      console.log("Renviada");
    });
}

function createEventPass(eventID, eventoIndex, inscritoKey, raceNumber) {
  console.log(eventID, eventoIndex, inscritoKey, raceNumber);
  const docRef = fb.firestore
    .collection("virtualEvents")
    .doc(eventID)
    .collection("eventPass");
  const date = Date.now() / 1000;

  const object = {
    userID: eventoIndex,
    code: inscritoKey,
    uses: 1,
    active: true,
    date: date,
    raceNumber: raceNumber,
  };

  return docRef.doc(inscritoKey).set(object);
}
// #########################################################################################
export function getFormData(eventoIndex, inputGroup) {
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.formsData}/${inputGroup}`
  );
  console.log("eventWeb");

  return (dispatch) => {
    dispatch({
      type: types.FORM_DATA,
      formData: {},
      inputGroup: null,
    });
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.FORM_DATA,
        formData: snapshot.val(),
        inputGroup: inputGroup,
      });
    });
  };
}
// #########################################################################################

export function eventCodes(eventoIndex) {
  console.log("Codigos del evento Evento evento");

  return (dispatch) => {
    var ref = fb.db.ref(
      `${fb.EventosCompletos}/${eventoIndex}/${fb.CodigosDescuento}${fb.Codigos}`
    );
    ref.once("value", (snapshot) => {
      console.log("codesList", snapshot.val());
      console.log("events participan value");
      dispatch({
        type: types.CODES_LIST,
        codesList: snapshot.val(),
      });
    });

    ref.on("child_changed", (snapshot) => {
      console.log(snapshot.val());
      console.log("child chaged");
      dispatch({
        type: types.CODES_LIST_CHANGED,
        codesListChanged: snapshot.val(),
        codesListChangedKey: snapshot.key,
      });
    });
    ref.limitToLast(1).on("child_added", (snapshot) => {
      console.log(snapshot.val());
      console.log("child added");
      dispatch({
        type: types.CODES_LIST_CHANGED,
        codesListChanged: snapshot.val(),
        codesListChangedKey: snapshot.key,
      });
    });
    ref.on("child_removed", (snapshot) => {
      console.log(snapshot.val());
      console.log("child removed");
      dispatch({
        type: types.CODES_LIST_REMOVED,
        codesListChanged: snapshot.val(),
        codesListChangedKey: snapshot.key,
      });
    });
  };
}
// #########################################################################################

export function eventCodesGroups(eventoIndex) {
  console.log("Codigos del evento Evento evento");

  return (dispatch) => {
    var ref = fb.db.ref(
      `${fb.EventosCompletos}/${eventoIndex}/${fb.CodigosDescuento}${fb.GruposCodigos}`
    );
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      console.log("events participan value");
      dispatch({
        type: types.CODES_GROUPS,
        codesGroups: snapshot.val(),
      });
    });
  };
}
// #########################################################################################

export function createEventCodes(
  eventoIndex,
  codeGroupKey,
  codeGroup,
  codesList
) {
  console.log("Crear Codigos del evento Evento evento");

  console.log("Route", eventoIndex);
  console.log("Route", codeGroupKey);
  console.log("Route", codeGroup);
  console.log("Route", codesList);

  const route = `${fb.EventosCompletos}${eventoIndex}/${fb.CodigosDescuento}`;
  var updates = {};
  // registro el grupo de códigos en la lista
  updates[`${route}${fb.GruposCodigos}${codeGroupKey}/`] = codeGroup;

  for (var i in codesList) {
    updates[`${route}${fb.Codigos}${i}/`] = codesList[i];
  }

  console.log("Route", route);
  console.log("Route", updates);

  return (dispatch) => {
    fb.db
      .ref()
      .update(updates)
      .then(function() {
        console.log("Códigos registrados");
        dispatch(showSnackbar(true, "Códigos generados correctamente", 5000));
      })
      .catch(function(error) {
        console.log(" ERROR Códigos NO registrados");
        dispatch(showSnackbar(true, "Error al generar los códigos", 5000));
      });
  };
}
// #########################################################################################
