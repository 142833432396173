import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

//import './PhotoEditorCss.css';
import $ from "jquery";
import Jcrop from "jquery-jcrop";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import { Collapsible, CollapsibleItem, Button } from "react-materialize";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Container from "@material-ui/core/Container";

import CropIcon from "@material-ui/icons/Crop";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import UndoIcon from "@material-ui/icons/Undo";
import DoneIcon from "@material-ui/icons/Done";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CropLandscapeIcon from "@material-ui/icons/CropLandscape";
import CropPortraitIcon from "@material-ui/icons/CropPortrait";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import CancelIcon from "@material-ui/icons/Cancel";

import Divider from "@material-ui/core/Divider";
import FiltersDialog from "./FiltersDialog";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import "../../Jcrop/jquery.Jcrop.min.css";

// tratar con
//import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';

const classes = {
  appBar: {
    position: "relative",
    background: "black",
  },
  title: {
    flex: 1,
  },
  ButtonStyleDefault: {
    color: "red",
  },
  logo: {
    height: "40px",
  },
  toolBar: {},
};

class PhotoEditorView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      anchoDiv: 0,
      altoDiv: 0,
      canvasKey: 0,
      cropping: false,
      image: null,
      anchoFoto: 0,
      altoFoto: 0,
      imgOrigen: "",
      photoOrientation: 1,
    };
    this.anchoFoto = 0;
    this.altoFoto = 0;
    this.imgOrigen = 0;
    this.ajutar = 1; // para ajustar la imagen a un tama;o menor y evitar errores al descargar
    this.canvas = 0;
    this.ctx = 0;
    this.JCROPAPI = 0;
  }

  cropper = React.createRef(null);

  //##################################################################
  _crop(e) {
    //console.log(this.cropper.getCroppedCanvas().toDataURL());
    this.x = e.detail.x;
    this.y = e.detail.y;
    this.w = e.detail.width;
    this.h = e.detail.height;
    console.log(this.w + " " + this.h + " " + this.x + " " + this.y);

    //console.log(this.refs.cropper.getCroppedCanvas().toDataURL());
  }
  //##################################################################
  finalizarCorteNew = () => {
    this.canvasFunction(this.cropper.getCroppedCanvas().toDataURL());
    this.setState({
      cropping: false,
    });
  };
  //##################################################################
  componentDidMount() {
    this.canvasFunction(this.props.urlPhoto);
  }

  //##################################################################
  canvasFunction = (url) => {
    console.log("canvasFunction");
    this.canvas = document.getElementById("canvas");
    this.ctx = this.canvas.getContext("2d");

    /* Enable Cross Origin Image Editing */
    this.imgOrigen = new Image();
    this.imgOrigen.crossOrigin = "";
    this.imgOrigen.src = url;
    this.imgOrigen.onload = () => {
      this.setState({ loading: false });

      if (this.ajutar == 1) {
        var AltoAnchoMax = 1500;

        if (this.imgOrigen.width > this.imgOrigen.height) {
          // foto landscape
          if (this.imgOrigen.width > AltoAnchoMax) {
            this.anchoFoto = AltoAnchoMax;
            this.altoFoto =
              (AltoAnchoMax * this.imgOrigen.height) / this.imgOrigen.width;
          } else {
            this.anchoFoto = this.imgOrigen.width;
            this.altoFoto = this.imgOrigen.height;
          }
        } else if (this.imgOrigen.height > this.imgOrigen.width) {
          // fofo portrait
          if (this.imgOrigen.height > AltoAnchoMax) {
            this.anchoFoto =
              (AltoAnchoMax * this.imgOrigen.width) / this.imgOrigen.height;
            this.altoFoto = AltoAnchoMax;
          } else {
            this.anchoFoto = this.imgOrigen.width;
            this.altoFoto = this.imgOrigen.height;
          }
        } else {
          // foto cuadrada
          if (this.imgOrigen.width > AltoAnchoMax) {
            this.anchoFoto = AltoAnchoMax;
            this.altoFoto =
              (AltoAnchoMax * this.imgOrigen.height) / this.imgOrigen.width;
          } else {
            this.anchoFoto = this.imgOrigen.width;
            this.altoFoto = this.imgOrigen.height;
          }
        }

        //resizeImagen();
        //insertaLogos();
      } else {
        this.anchoFoto = this.imgOrigen.width;
        this.altoFoto = this.imgOrigen.height;
      }
      console.log(this.altoFoto);
      console.log(this.anchoFoto);
      this.canvas.width = this.anchoFoto;
      this.canvas.height = this.altoFoto;

      if (this.imgOrigen.width < this.imgOrigen.height) {
        // foto portrait
        this.setState({
          photoOrientation: 1,
        });
      } else if (this.imgOrigen.width > this.imgOrigen.height) {
        // foto landscape
        this.setState({
          photoOrientation: 2,
        });
      } else {
        // foto cuadrada
        this.setState({
          photoOrientation: 3,
        });
      }

      this.ctx.drawImage(this.imgOrigen, 0, 0, this.anchoFoto, this.altoFoto);
      if (this.anchoFoto < this.altoFoto) {
        this.setState({ anchoDiv: "auto", altoDiv: "500px" });
      } else {
        this.setState({ anchoDiv: "500px", altoDiv: "auto" });
      }

      console.log("ancho" + this.anchoFoto + " altoFoto " + this.altoFoto);
      console.log(
        "anchodiv" + this.state.anchoDiv + " altodiv " + this.state.altoDiv
      );

      var textoParams = {
        txt: this.props.resultado.nombre,
        anchoPorcentajePortrait: 0.7,
        anchoPorcentajeLandscape: 0.4,
        anchoPorcentajeSquare: 0.5,
        x: this.anchoFoto / 2,
        y: this.altoFoto * 0.6,
        colorTexto: "white",
        AjusteFuente: 2,
        sombra: true,
        Alineacion: "center",
      };

      //this.insertaTexto(textoParams);
      var textoParams = {
        txt: this.props.resultado.to,
        anchoPorcentajePortrait: 0.7,
        anchoPorcentajeLandscape: 0.4,
        anchoPorcentajeSquare: 0.5,
        x: this.anchoFoto / 2,
        y: this.altoFoto * 0.8,
        colorTexto: "white",
        AjusteFuente: 1,
        sombra: true,
        Alineacion: "center",
      };

      //this.insertaTexto(textoParams);

      this.insertaLogos();
    };
  };

  //##################################################################
  insertaLogos = () => {
    for (var i in this.props.eventPhotoConfiguration.logos) {
      var logo = this.props.eventPhotoConfiguration.logos[i].rutaLogo;
      var anchoLogoPorcentaje = this.props.eventPhotoConfiguration.logos[i]
        .anchoLogo;
      var margen = this.props.eventPhotoConfiguration.logos[i].margen;
      var posicionMarca = this.props.eventPhotoConfiguration.logos[i].posicion;
      this.insertaLogo(logo, anchoLogoPorcentaje, margen, posicionMarca);
    }
  };

  //##################################################################
  insertaLogo = (logo, anchoLogoPorcentaje, margen, posicionMarca) => {
    //logo = 'KmetasV4/VistasV4/Configuracion/Imagenes/LogosKmetas/Logo3alta resolucion.png';
    //anchoLogoPorcentaje = 0.30;
    //margen = 30;
    //posicionMarca = 4;

    // creo la imagen con la ruta recibida
    var img = new Image();
    img.crossOrigin = "";
    img.src = logo;

    // descargo del servidor y calculo ancho y alto.
    img.onload = () => {
      var anchoLogo = img.width;
      var altoLogo = img.height;

      // ajusto tamano en relacion al ancho especificado que ocupara en la foto
      var anchoLogoFinal = this.anchoFoto * anchoLogoPorcentaje;
      var altoLogoFinal = (anchoLogoFinal * altoLogo) / anchoLogo;

      var posX = margen;
      var posY = margen;

      switch (posicionMarca) {
        case 2: // arriba a la derecha
          posX = this.anchoFoto - anchoLogoFinal - margen;
          break;
        case 3: // abajo a la izquierda
          posY = this.altoFoto - altoLogoFinal - margen;
          break;
        case 4: // abajo a la derecha
          posX = this.anchoFoto - anchoLogoFinal - margen;
          posY = this.altoFoto - altoLogoFinal - margen;
          break;
      } // 1 o 0 arriba a la izquierda

      // dibujo la imagen sobre la foto original
      this.ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        posX,
        posY,
        anchoLogoFinal,
        altoLogoFinal
      );
      this.passCanvasToImg();
    };
  };
  //##################################################################
  insertaMarco = (marco) => {
    console.log("marco click" + marco);
    //marco = 'KmetasV4/VistasV4/Configuracion/Imagenes/LogosKmetas/MarcoCTriatlonMerida.png';
    // creo la imagen con la ruta recibida
    var img = new Image();
    img.crossOrigin = "";
    img.src = marco;

    // descargo del servidor y calculo ancho y alto.
    img.onload = () => {
      // dibujo la imagen sobre la foto original
      this.ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        this.anchoFoto,
        this.altoFoto
      );
      this.insertaLogos();
    };
  };

  //##################################################################
  descargaFinal = (e) => {
    e.preventDefault();

    // esta es la parte que descarga en about blank el texto dice Descarga opcion 3
    console.log("descarga");

    // var dato = this.canvas.toDataURL("image/jpeg");
    // dato = dato.replace("image/jpeg", "image/octet-stream");
    // document.location.href = dato;

    var link = document.createElement("a");
    link.download = this.props.resultado.nombre + ".jpeg";
    link.href = document.getElementById("canvas").toDataURL("image/jpeg");
    link.click();
  };
  //##################################################################
  passCanvasToImg = () => {
    this.setState({
      imgOrigen: document.getElementById("canvas").toDataURL("image/jpeg"),
    });
  };
  //##################################################################
  reversarCambios = (event) => {
    this.setState({
      cropping: false,
    });
    console.log("reversarCambios");
    event.preventDefault();
    this.canvasFunction(this.props.urlPhoto);
  };
  //##################################################################

  habilitarCorte = (event, aspectRatio) => {
    this.setState({
      cropping: true,
      aspectRatio: aspectRatio,
    });
    event.preventDefault();
  };

  //##################################################################
  cancelCrop = () => {
    this.setState({
      cropping: false,
    });
    //this.JCROPAPI.destroy();
  };
  //##################################################################

  insertaTexto = (textoParams) => {
    // txt = texto a insertar
    // anchoPorcentaje = ancho del texto en relacion al ancho de la foto
    // x = inicio del texto segun la alineacion
    // y = inicio del texto de forma vertical
    // colorTexto
    // AjusteFuente= para estirar la fuente o mantener igual (1) segun dise;o valor de 1 en adelante.
    // sombra= 1 para colocar sobra negra al texto, 0 para no colocar
    // Alineacion = puede ser 'start', 'end', 'center'

    //ejm de coomo llamar la funcion
    // this.insertaTexto(textoParams);

    // var textoParams = {
    //     txt:this.props.resultado.nombre,
    //     anchoPorcentajePortrait:0.7,
    //     anchoPorcentajeLandscape:0.4,
    //     anchoPorcentajeSquare:0.5,
    //     x : this.anchoFoto/2,
    //     y:this.altoFoto/2,
    //     colorTexto:'white',
    //     AjusteFuente:2,
    //     sombra:true,
    //     Alineacion:'center'
    // }

    var fuente = 0;
    var i = 0;
    var anchoTextoFinal;
    if (this.state.photoOrientation == 1) {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajePortrait;
    } else if (this.state.photoOrientation == 2) {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajeLandscape;
    } else {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajeSquare;
    }
    this.ctx.textAlign = textoParams.Alineacion;

    while (i < anchoTextoFinal) {
      fuente++;
      console.log(fuente);
      this.ctx.font = fuente + "px Arial";
      i = this.ctx.measureText(textoParams.txt).width;
    }

    // para ajustar el alto del texto sin afectar el ancho maximo seleccioando
    this.ctx.font = fuente * textoParams.AjusteFuente + "px Arial";

    // para colocar el texto atras como sombra
    if (textoParams.sombra) {
      this.ctx.fillStyle = "black";
      this.ctx.fillText(
        textoParams.txt,
        textoParams.x + 3,
        textoParams.y + 3,
        anchoTextoFinal
      );
    }
    this.ctx.fillStyle = textoParams.colorTexto;
    this.ctx.fillText(
      textoParams.txt,
      textoParams.x,
      textoParams.y,
      anchoTextoFinal
    );

    return fuente;
  };
  //##################################################################
  render() {
    let loading = "";
    if (this.state.loading) {
      loading = (
        <div id="LoadingFoto" className="preloader-wrapper big active">
          <div className="spinner-layer spinner-blue-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    } else {
      loading = "";
    }

    var framesArray = [];

    for (var i in this.props.eventPhotoConfiguration.marcos) {
      framesArray.push(this.props.eventPhotoConfiguration.marcos[i]);
    }
    console.log(framesArray);

    let frames = (
      <Grid>
        <Grid xs={12}>
          <h6 style={{ textAlign: "center" }}>Marcos</h6>
        </Grid>
        <Grid xs={12}>
          <FiltersDialog
            resultado={this.props.resultado}
            frames={framesArray}
            callback={this.insertaMarco}
          />
        </Grid>
      </Grid>
    );

    let CropArea = (
      <Grid>
        <Grid xs={12}>
          <h6 style={{ textAlign: "center" }}>Cortar</h6>
        </Grid>
        <Grid xs={12}>
          <IconButton aria-label="landScape" color="inherit">
            <CropLandscapeIcon
              onClick={(e) => this.habilitarCorte(e, 16 / 9)}
            />
          </IconButton>
          <IconButton aria-label="portrait" color="inherit">
            <CropPortraitIcon onClick={(e) => this.habilitarCorte(e, 2 / 3)} />
          </IconButton>
          <IconButton aria-label="square" color="inherit">
            <CropSquareIcon onClick={(e) => this.habilitarCorte(e, 1)} />
          </IconButton>
        </Grid>
      </Grid>
    );

    if (this.state.cropping) {
      CropArea = (
        <Grid>
          <Grid xs={12}>
            <h6 style={{ textAlign: "center" }}>Finalizar Corte</h6>
          </Grid>
          <Grid xs={12} style={{ textAlign: "center" }}>
            <IconButton aria-label="showfilters" color="inherit">
              <DoneIcon onClick={this.finalizarCorteNew} />
            </IconButton>
            <IconButton aria-label="showfilters" color="inherit">
              <CancelIcon onClick={this.cancelCrop} />
            </IconButton>
          </Grid>
        </Grid>
      );
    }

    let imageView;
    if (this.state.cropping) {
      imageView = (
        <Cropper
          ref={(cropper) => {
            this.cropper = cropper;
          }}
          src={this.props.urlPhoto}
          style={{ maxWidth: "auto", maxHeight: "500px" }}
          // Cropper.js options
          aspectRatio={this.state.aspectRatio}
          guides={false}
          crop={this._crop.bind(this)}
          background={true}
          viewMode={1}
          zoomable={true}
          zoomOnTouch={true}
        />
      );
    } else {
      imageView = (
        <img
          id="imagen"
          src={this.state.imgOrigen}
          style={{
            maxWidth: "auto",
            maxHeight: "500px",
          }}
        />
      );
    }

    return (
      <div>
        <AppBar style={classes.appBar}>
          <Toolbar style={classes.toolBar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={classes.title}>
              {this.props.title}
            </Typography>

            {CropArea}
            <Grid xs={1} style={{ backgroundColor: "red" }}></Grid>
            {frames}
            <Grid xs={1} style={{ backgroundColor: "red" }}></Grid>

            <IconButton aria-label="Deshacer" edge="end" color="inherit">
              <UndoIcon onClick={this.reversarCambios} />
            </IconButton>
            <IconButton aria-label="DEscarga" edge="end" color="inherit">
              <GetAppIcon onClick={this.descargaFinal} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Grid container spacing={3} alignItems="center">
          <Grid item justify="center" xs={12} style={{ textAlign: "center" }}>
            <br />

            {loading}

            {imageView}
            <canvas
              className="FotoEditar1"
              id="canvas"
              style={{ display: "none" }}
            ></canvas>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventPhotoConfiguration: state.events.eventPhotoConfiguration,
    //eventPhotoConfiguration:eventPhotoConfiguration
  };
};

const PhotoEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoEditorView);

export default PhotoEditor;

const eventPhotoConfiguration = {
  logos: [
    {
      anchoLogo: 0.3,
      codLogo: 2,
      descripcion: "Logo Dashport",
      margen: 30,
      posicion: 4,
      rutaLogo:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73",
    },
  ],
  marcos: [
    {
      codMarco: 1,
      descripcion: "Marco Blanco",
      rutaMarco:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeBlancoRecto.png?alt=media&token=4b64abde-f3ef-476c-9dbf-b2d3f30350be",
      tipo: "Estandar",
    },
    {
      codMarco: 2,
      descripcion: "Marco Negro",
      rutaMarco:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeNegroRecto.png?alt=media&token=7c73c524-8033-4285-be82-8b818b1cf21e",
      tipo: "Estandar",
    },
  ],
};
