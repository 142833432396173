import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { InputAdornment, Paper, TextField } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { useForm } from "react-hook-form";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import Filter1Icon from "@material-ui/icons/Filter1";
import { showSnackbar } from "../../../../../data-store/actions/atlete-actions";
import { changesRegistryFunc, db } from "../../../../../firebase/firebasejs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "500px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function UpdateRaceNumber(props) {
  const {
    callback,
    eventIndex,
    raceNumber,
    idRegistro,
    eventParticipants,
    ParticipantDetail,
  } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );

  console.log("props", props);

  const classes = useStyles();
  const [view, setView] = useState(false);
  const [number, setNumber] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  function onSubmitAny(raceNumber) {
    dispatch(showSnackbar(true, "Asignando Folio", 4000));
  }
  function onSubmitSpecific(raceNumber) {
    if (number > 0 && number !== "") {
      dispatch(showSnackbar(true, "Asignando Folio " + number, 4000));
    } else {
      dispatch(showSnackbar(true, "Ingresa el folio a asignar", 4000));
    }
  }

  function findNumber(numero, idRegistro) {
    for (var i in eventParticipants) {
      if (
        eventParticipants[i].numero === numero ||
        eventParticipants[i].numero === "'" + numero + "'"
      ) {
        return true;
      }
    }
    return false;
  }

  function updateRaceNumber(
    especifico,
    liberarAnterior,
    idRegistro,
    raceNumber,
    eventIndex
  ) {
    var updates = {};
    var changesRegistry = {};

    if (!especifico) {
      if (liberarAnterior) {
        updates[
          `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/numero/`
        ] = null;
        updates[
          `eventosCompletos/${eventIndex}/inscritos/folios/num_${raceNumber}/`
        ] = null;
        console.log(
          `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/numero/`
        );
        changesRegistry = changesRegistryFunc(
          idRegistro,
          ParticipantDetail,
          userID,
          athleteAccount,
          13,
          `Folio anterior ${raceNumber} (Liberado)`
        );
        updates[
          `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/changesRegistry/`
        ] = changesRegistry;
        return db
          .ref()
          .update(updates)
          .then((snap) => {
            dispatch(showSnackbar(true, "Cambio Ejecutado", 4000));
          });
      } else {
        updates[
          `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/numero/`
        ] = null;
        console.log(
          `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/numero/`
        );
        changesRegistry = changesRegistryFunc(
          idRegistro,
          ParticipantDetail,
          userID,
          athleteAccount,
          13,
          `Folio anterior ${raceNumber} (No Liberado)`
        );
        updates[
          `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/changesRegistry/`
        ] = changesRegistry;

        return db
          .ref()
          .update(updates)
          .then((snap) => {
            dispatch(showSnackbar(true, "Cambio Ejecutado", 4000));
          });
      }
    } else {
      if (liberarAnterior) {
        var nuevoNumero = number;
        if (
          nuevoNumero !== "" &&
          Number.isInteger(nuevoNumero / 1) &&
          nuevoNumero > 0
        ) {
          if (!findNumber(nuevoNumero / 1)) {
            updates[
              `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/numero/`
            ] = nuevoNumero;
            // updates[
            //   `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/mails/sendMail/`
            // ] = true;
            updates[
              `eventosCompletos/${eventIndex}/inscritos/folios/num_${raceNumber}/`
            ] = null;
            updates[
              `eventosCompletos/${eventIndex}/inscritos/folios/num_${nuevoNumero}/atleta/`
            ] = idRegistro;
            console.log(
              `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/numero/`
            );
            changesRegistry = changesRegistryFunc(
              idRegistro,
              ParticipantDetail,
              userID,
              athleteAccount,
              13,
              `Folio anterior ${raceNumber} (Liberado)`
            );
            updates[
              `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/changesRegistry/`
            ] = changesRegistry;

            return db
              .ref()
              .update(updates)
              .then((snap) => {
                dispatch(showSnackbar(true, "Cambio Ejecutado", 4000));
              });
          } else {
            dispatch(
              showSnackbar(true, "Número no disponible para asignar", 4000)
            );
          }
        } else {
          dispatch(showSnackbar(true, "Introduce el folio a asignar", 4000));
        }
      } else {
        var nuevoNumero = number;
        if (
          nuevoNumero !== "" &&
          Number.isInteger(nuevoNumero / 1) &&
          nuevoNumero > 0
        ) {
          if (!findNumber(nuevoNumero / 1)) {
            updates[
              `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/numero/`
            ] = nuevoNumero;
            // updates[
            //   `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/mails/sendMail/`
            // ] = true;
            updates[
              `eventosCompletos/${eventIndex}/inscritos/folios/num_${nuevoNumero}/atleta/`
            ] = idRegistro;
            console.log(
              `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/numero/`
            );
            changesRegistry = changesRegistryFunc(
              idRegistro,
              ParticipantDetail,
              userID,
              athleteAccount,
              13,
              `Folio anterior ${raceNumber} (No Liberado)`
            );
            updates[
              `eventosCompletos/${eventIndex}/inscritos/listadoInscritos/${idRegistro}/changesRegistry/`
            ] = changesRegistry;

            return db
              .ref()
              .update(updates)
              .then((snap) => {
                dispatch(
                  showSnackbar(
                    true,
                    `Cambio Ejecutado Folio anterior ${raceNumber} (No Liberado)`,
                    4000
                  )
                );
              });
          } else {
            dispatch(
              showSnackbar(true, "Número no disponible para asignar", 4000)
            );
          }
        } else {
          dispatch(showSnackbar(true, "Introduce el folio a asignar", 4000));
        }
      }
    }
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
        padding: "20px",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        <Typography color="primary" component="h2" variant="h3">
          Cambio de folio del atleta
        </Typography>
        {/* <Typography color="primary" component="h6" variant="p">
          Utilza esta sección para cambiar el número de competencia asignado al
          atleta
        </Typography> */}
        <Typography color="primary" component="h6" variant="h5">
          Número actual : {raceNumber}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{ padding: "20px", textAlign: "center" }}
      >
        <Paper
          style={{
            backgroundColor: "#EEEEEE",
            padding: "5px",
            height: "500px",
            verticalAlign: "center",
          }}
        >
          <Filter1Icon
            color="primary"
            style={{ fontSize: "70px", margin: "10px" }}
          />
          <Typography color="primary" component="h6" variant="subtitle1">
            Asignar un folio específico
          </Typography>
          <div style={{ padding: "10px" }}>
            <TextField
              label="Nuevo Número"
              style={{ width: "60%" }}
              helperText={"Campo requerido"}
              InputProps={{
                style: {
                  marginBottom: 0,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Filter1Icon color="primary" />
                  </InputAdornment>
                ),
              }}
              defaultValue={""}
              fullWidth
              inputRef={register({ required: true, min: 1, max: 99999999 })}
              name="raceNumberNew"
              type="number"
              onChange={(e) => setNumber(e.target.value / 1)}
            />
          </div>
          <Button
            onClick={() =>
              updateRaceNumber(
                true,
                true,
                idRegistro,
                raceNumber / 1,
                eventIndex
              )
            }
            variant="contained"
            color="primary"
            style={{
              width: "150px",
              margin: "10px",
            }}
          >
            Asignar el folio {number} y liberar el folio anterior
          </Button>
          <Button
            onClick={() =>
              updateRaceNumber(true, false, idRegistro, raceNumber, eventIndex)
            }
            variant="contained"
            color="primary"
            style={{
              width: "150px",
              margin: "10px",
            }}
          >
            Solo asignar folio {number}
          </Button>
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        style={{ padding: "20px", textAlign: "center" }}
      >
        <Paper
          style={{
            backgroundColor: "#EEEEEE",
            padding: "5px",
            height: "500px",
          }}
        >
          <FormatListNumberedIcon
            color="primary"
            style={{ fontSize: "70px", margin: "10px" }}
          />
          <Typography color="primary" component="h6" variant="subtitle1">
            Asignar primer folio disponible
          </Typography>
          <Button
            onClick={() =>
              updateRaceNumber(false, true, idRegistro, raceNumber, eventIndex)
            }
            variant="contained"
            color="primary"
            style={{
              width: "150px",
              margin: "10px",
              height: "120px",
            }}
          >
            Asignar y liberar el folio anterior
          </Button>
          <Button
            onClick={() =>
              updateRaceNumber(false, false, idRegistro, raceNumber, eventIndex)
            }
            variant="contained"
            color="primary"
            style={{
              width: "150px",
              margin: "10px",
              height: "120px",
            }}
          >
            Solo asignar folio nuevo
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
